import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setActiveNexusFilter } from "../../../store/nexusFilters";
import { getPlanningDates } from "../../../store/nexusFilters/action";
import Accordion from "../../UI/Accordion";
import { resetGanttChartErrorMessage } from "../../../store/ganttChart";
import { ActiveNexusFilter } from "../../../types/ganttChart";
import labels from "../../../utils/labels";
import { useEffect, useState } from "react";

const HeaderFilters = ({ options }: { options: ActiveNexusFilter[] }) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const filterID = searchParams.get("filterId");
  const filter = searchParams.get("filter");
  const { activeNexusFilters, selectedActiveNexusFilter } = useAppSelector(
    (state) => state.nexusFiltersSlice
  );

  const [defaultIndex, setDefaultIndex] = useState<number>(0);

  const handleFilterChange = (filterName: string, value?: string) => {
    dispatch(resetGanttChartErrorMessage());
    setSearchParams((prevParams) => {
      if (value) {
        prevParams.set(filterName, value);
      } else {
        prevParams.delete(filterName);
      }
      return prevParams;
    });
  };

  useEffect(() => {
    if (filterID && activeNexusFilters) {
      const selectedFilter = options.filter(
        (option) => option.filterId === parseInt(filterID)
      );
      dispatch(setActiveNexusFilter(selectedFilter[0]));
      setDefaultIndex(
        activeNexusFilters!.findIndex(
          (obj) => obj.filterId === selectedActiveNexusFilter?.filterId
        )
      );
    } else if (filter && activeNexusFilters) {
      const selectedFilter = options.filter(
        (option) => option.id === parseInt(filter)
      );
      dispatch(setActiveNexusFilter(selectedFilter[0]));
      setDefaultIndex(
        activeNexusFilters!.findIndex(
          (obj) => obj.id === selectedActiveNexusFilter?.id
        )
      );
    }
  }, [activeNexusFilters, filterID, filter]);

  return (
    <Accordion
      key={defaultIndex}
      variant="SECONDARY"
      onChange={(option: ActiveNexusFilter) => {
        dispatch(getPlanningDates(option.id));
        dispatch(setActiveNexusFilter(option));
        handleFilterChange("filter", option.id.toString());
      }}
      options={activeNexusFilters!}
      labelKey="filter"
      defaultText={labels.filter}
      displayOption={defaultIndex}
    />
  );
};

export default HeaderFilters;
