import {
  findJobIdByFilterAndDate,
  latestMessageIsOfType,
  messageStreamIncludesType,
} from "./helpers";
import { useAppSelector } from "../hooks";
import { Job } from "../../hooks/WebSocketProvider";
import { useMemo } from "react";

const useMessagesForJobBasedOnSelectedFilterAndDate = () => {
  const { selectedNexusFilterDate, selectedActiveNexusFilter } = useAppSelector(
    (state) => state.nexusFiltersSlice,
  );
  const jobs = useAppSelector(
    (state) => state.serverAsyncJobsSlice.websocketJobs,
  );

  return useMemo(() => {
    if (!selectedActiveNexusFilter || !selectedNexusFilterDate)
      return undefined;

    const jobId = findJobIdByFilterAndDate(
      jobs,
      selectedActiveNexusFilter.id,
      selectedNexusFilterDate,
    );

    return {
      selectedFilter: selectedActiveNexusFilter,
      selectedNexusFilterDate,
      messages: !!jobId ? jobs[jobId] : undefined,
    };
  }, [jobs, selectedActiveNexusFilter, selectedNexusFilterDate]);
};

/**
 * Get the selected filter and date. This object is undefined if no Nexus/Pondoo plan ids are present on the planning date.
 * This is to ensure that data will be fetched in Header.tsx only when we are ready
 */
export const useSelectedFilterData = () => {
  const planningDates = useAppSelector(
    (state) => state.nexusFiltersSlice.planningDates,
  );

  const { selectedActiveNexusFilter, selectedNexusFilterDate } = useAppSelector(
    (state) => state.nexusFiltersSlice,
  );
  return useMemo(() => {
    if (!planningDates) return;
    const selectedDate = planningDates.find(
      (date) => date.planningDate === selectedNexusFilterDate,
    );
    if (!selectedDate) return;
    return {
      selectedActiveNexusFilter,
      selectedNexusFilterPlanningDate: selectedDate,
    };
  }, [planningDates, selectedActiveNexusFilter, selectedNexusFilterDate]);
};

export const useNexusPlanStatus = () => {
  const planningDates = useAppSelector(
    (state) => state.nexusFiltersSlice.planningDates,
  );
  const selectedFilterAndDateData =
    useMessagesForJobBasedOnSelectedFilterAndDate();

  return useMemo(() => {
    const defaultStatus = {
      isLoading: false,
      isReady: false,
      isError: false,
    };

    if (
      !planningDates ||
      !selectedFilterAndDateData ||
      !selectedFilterAndDateData.selectedFilter ||
      !selectedFilterAndDateData.selectedNexusFilterDate
    ) {
      return defaultStatus;
    }

    const activePlanningDate = planningDates.find(
      (date) =>
        date.planningDate === selectedFilterAndDateData.selectedNexusFilterDate,
    );

    // First check if we have a plan ready from the backend
    if (!!activePlanningDate && !!activePlanningDate.nexusIdSolution) {
      return { ...defaultStatus, isReady: true };
    }

    // If no websocket process is ongoing and no planning date was found, nothing is happening
    if (!selectedFilterAndDateData?.messages) {
      return defaultStatus;
    }

    // If we're here, we have an ongoing or finished websocket process
    const isLoading =
      latestMessageIsOfType(
        [
          "NexusLandingProgressDataRetrievalInitiatedMessage",
          "NexusLandingDataRetrievalAlreadyRunningMessage",
        ],
        selectedFilterAndDateData.messages,
      ) || selectedFilterAndDateData.messages.length === 0;

    const isReady = messageStreamIncludesType(
      "NexusLandingProgressNexusDataReadyMessage",
      selectedFilterAndDateData.messages,
    );

    const isError = messageStreamIncludesType(
      "NexusLandingProgressDataRetrievalFailedMessage",
      selectedFilterAndDateData.messages,
    );
    return {
      ...defaultStatus,
      isReady,
      isLoading: isLoading && !isError,
      isError,
    };
  }, [planningDates, selectedFilterAndDateData]);
};

export type PondooPlanStatus = {
  isLoading: boolean;
  isReady: boolean;
  isError: boolean;
  job: Job | undefined;
};

export const usePondooPlanStatus = (): PondooPlanStatus => {
  const planningDates = useAppSelector(
    (state) => state.nexusFiltersSlice.planningDates,
  );
  const selectedFilterAndDateData =
    useMessagesForJobBasedOnSelectedFilterAndDate();
  const latestJobs = useAppSelector(
    (state) => state.serverAsyncJobsSlice.jobEntities,
  );

  return useMemo(() => {
    const defaultStatus: PondooPlanStatus = {
      isLoading: false,
      isReady: false,
      isError: false,
      job: undefined,
    };

    if (
      !planningDates ||
      !selectedFilterAndDateData ||
      !selectedFilterAndDateData.selectedFilter ||
      !selectedFilterAndDateData.selectedNexusFilterDate
    ) {
      return defaultStatus;
    }

    const activePlanningDate = planningDates.find(
      (date) =>
        date.planningDate === selectedFilterAndDateData.selectedNexusFilterDate,
    );

    if (!!activePlanningDate && !!activePlanningDate.pondooIdSolution) {
      return {
        ...defaultStatus,
        isReady: true,
        job: latestJobs.find(
          (job) => job.idScenario === activePlanningDate?.idScenario,
        ),
      };
    }

    if (!selectedFilterAndDateData.messages) {
      return defaultStatus;
    }

    // If a websocket process is ongoing, check the message stream
    const optimizationRunning = latestMessageIsOfType(
      ["NexusLandingProgressOptimizationInitiated"],
      selectedFilterAndDateData.messages.filter((msg) =>
        msg.bridgeMessageType.toLowerCase().includes("optimization"),
      ),
    );
    const optimizationReady = messageStreamIncludesType(
      "NexusLandingProgressOptimizationFinished",
      selectedFilterAndDateData.messages,
    );
    const latestJob = selectedFilterAndDateData.messages
      .filter(
        (msg) =>
          msg.bridgeMessageType === "NexusLandingProgressOptimizationFinished",
      )
      .pop();

    return {
      ...defaultStatus,
      isReady: optimizationReady,
      isLoading: optimizationRunning,
      job: latestJob?.data as Job,
      isError: false,
    };
  }, [planningDates, selectedFilterAndDateData, latestJobs]);
};
