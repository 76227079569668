import { PieChart } from "@mui/x-charts/PieChart";
import labels from "../../utils/labels";
import KpiCard from "./KpiCard";
import { KpiPlannedTime } from "../../types/kpi";
import { ReactComponent as ClockIcon } from "../../assets/icons/clock.svg";
import { useEffect, useRef } from "react";
import KPITextLine from "../UI/kpi/KPITextLine";
import ComparisonChip from "../UI/kpi/ComparisonChip";

const PlannedTimeCard = ({
  kpiList,
  compareKpiList,
  compare,
}: {
  kpiList: KpiPlannedTime;
  compareKpiList: KpiPlannedTime;
  compare: boolean;
}) => {
  const pieChart = useRef(null);

  useEffect(() => {
    const timeWithCitizensPath = findPathsByFill(
      "var(--col-after-eight-light)"
    );
    const otherTasksPath = findPathsByFill("var(--col-mocha-late)");
    const transportationPath = findPathsByFill("var(--col-grey-3)");

    /* Add border color to each path, based on their color. */
    timeWithCitizensPath.forEach((path) => {
      path.style.stroke = "var(--col-after-eight)"; // Set stroke color
      path.style.strokeWidth = "4px";
    });
    otherTasksPath.forEach((path) => {
      path.style.stroke = "var(--col-mocha-cappucino)"; // Set stroke color
      path.style.strokeWidth = "4px";
    });
    transportationPath.forEach((path) => {
      path.style.stroke = "var(--col-grey-5)"; // Set stroke color
      path.style.strokeWidth = "4px";
    });
  }, [pieChart.current]);

  /* Function to find and compare path based on exact color. */
  function findPathsByFill(fillColor: string) {
    // Get all path elements
    const paths = document.querySelectorAll("path");

    // Convert to array and filter by fill
    return Array.from(paths).filter((path) => {
      const computedStyle = window.getComputedStyle(path);
      const actualFill = computedStyle.fill;

      // Convert both colors to RGB format for comparison
      const colorToRGB = (color: string) => {
        // Create a temporary div to compute color
        const div = document.createElement("div");
        div.style.color = color;
        document.body.appendChild(div);
        const rgbColor = window.getComputedStyle(div).color;
        document.body.removeChild(div);
        return rgbColor;
      };

      return colorToRGB(actualFill) === colorToRGB(fillColor);
    });
  }
  return (
    <KpiCard title={labels.plannedTime} icon={<ClockIcon />} classes="w-[60%]">
      <div className="flex gap-spacing-xl">
        <div className="w-[30%]">
          <PieChart
            ref={pieChart}
            width={170}
            height={170}
            series={[
              {
                data: [
                  {
                    id: 0,
                    value: kpiList.percentVisitTime,
                    color: "var(--col-after-eight-light)",
                  },
                  {
                    id: 1,
                    value: kpiList.percentOtherTime,
                    color: "var(--col-grey-3)",
                  },
                  {
                    id: 2,
                    value: kpiList.percentDriveTime,
                    color: "var(--col-mocha-late)",
                  },
                ],
                innerRadius: 52,
                outerRadius: 70,
                paddingAngle: 6,
                cornerRadius: 100,
                cx: 70,
                cy: 70,
              },
            ]}
          />
        </div>
        <div className="flex flex-col w-full gap-spacing-xl">
          <KPITextLine
            compare={compare}
            title={labels.timeWithCitizens}
            value={`${kpiList.percentVisitTime}%`}
            color="var(--col-after-eight-light)"
            borderColor="var(--col-after-eight)"
          >
            {compare && (
              <ComparisonChip
                positiveIndicator="POSITIVE"
                value={
                  kpiList.percentVisitTime - compareKpiList.percentVisitTime
                }
                id={compareKpiList.percentVisitTime}
              />
            )}
          </KPITextLine>
          <KPITextLine
            compare={compare}
            title={labels.transportation}
            value={`${kpiList.percentDriveTime.toString()}%`}
            color="var(--col-mocha-late)"
            borderColor="var(--col-mocha-cappucino)"
          ></KPITextLine>
          <KPITextLine
            compare={compare}
            title={labels.otherTasks}
            value={`${kpiList.percentOtherTime.toString()}%`}
            color="var(--col-grey-3)"
            borderColor="var(--col-grey-5)"
          ></KPITextLine>
        </div>
      </div>
    </KpiCard>
  );
};

export default PlannedTimeCard;
