import LeftSideColumns from "./horizontalGanttChart/leftsideColumn/LeftSideColumns";
import { ReactComponent as BlackArrowRight } from "../../assets/icons/black-arrow-right.svg";

import { useAppDispatch } from "../../store/hooks";
import { jump } from "../../store/timeline";
import IconButtonComponent from "../UI/buttons/IconButton";
import ganttCHartValues from "../../utils/ganttChartValues";
import SharedTimelineComponent from "./horizontalGanttChart/header/SharedTimelineComponent";
import { GanttChartData } from "../../types/ganttChart";

const HorizontalGanttWrapper = ({
  ganttChartData,
}: {
  ganttChartData: GanttChartData;
}) => {
  const dispatch = useAppDispatch();

  return (
    <>
      <div className="flex">
        <IconButtonComponent
          id="moveBackwardIcon"
          icon={<BlackArrowRight style={{ transform: "rotate(180deg)" }} />}
          onClick={() => dispatch(jump(-ganttCHartValues.jumpInHours))}
        />
        <IconButtonComponent
          id="moveBackwardIcon"
          icon={<BlackArrowRight />}
          onClick={() => dispatch(jump(ganttCHartValues.jumpInHours))}
        />
      </div>

      <div className="relative w-100% h-[120px]">
        <LeftSideColumns employees={ganttChartData.employees} />
        <SharedTimelineComponent />
      </div>
    </>
  );
};

export default HorizontalGanttWrapper;
