class comparisonUtil {
  static getRoundedComparison = (minuend: number, subtrahend: number) => {
    return Math.round((minuend - subtrahend) * 10) / 10;
  };

  static getRoundedValue = (value: number) => {
    return Math.round(value * 10) / 10;
  };
}

export default comparisonUtil;
