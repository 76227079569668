import ganttCHartValues from "../../../../utils/ganttChartValues";
import ganttChartStyles from "../../../../styles/ganttChart/ganttChart.module.css";

const LeftSideColumnsHeader = () => {
  return (
    <div
      className={ganttChartStyles.leftSideColumnHeader}
      style={{
        width: ganttCHartValues.nameColumnWidthString,
        height: ganttCHartValues.headerRowHeight,
      }}
    >
      <p>Medarbejdere</p>
      <span className="flex justify-between w-[180px]">
        <p className={ganttChartStyles.platformField}>Platform</p>
        <p className={ganttChartStyles.obsField}>Obs</p>
      </span>
    </div>
  );
};

export default LeftSideColumnsHeader;
