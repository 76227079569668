import { Outlet } from "react-router-dom";
import PageWrapper from "../components/UI/pageStructure/PageWrapper";

const Layout = () => {
  return (
    <PageWrapper>
      <Outlet />
    </PageWrapper>
  );
};

export default Layout;
