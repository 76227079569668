import hoverBoxStyles from "../../../styles/hover.module.css";

const HoverBoxTitle = ({
  title,
  icon,
}: {
  title: string;
  icon?: React.ReactNode;
}) => {
  return (
    <div className={hoverBoxStyles.titleWrapper}>
      <h4 className={hoverBoxStyles.title}>{title}</h4> {icon}
    </div>
  );
};

export default HoverBoxTitle;
