import buttonStyles from "../../../styles/UI/buttons.module.css";
import { ButtonVariantType } from "../../../types/buttons";

const StandardButton = ({
  icon,
  children,
  variant,
  onClick,
  classes,
  disable,
  width,
}: {
  icon?: React.ReactNode;
  children: React.ReactNode;
  variant: ButtonVariantType;
  onClick: () => void;
  classes?: string;
  disable?: boolean;
  width?: string;
}) => {
  const getButtonClassBasedOnVariant = () => {
    switch (variant) {
      case "PRIMARY":
        return buttonStyles.standardButtonPrimary;
      case "SECONDARY":
        return buttonStyles.standardButtonSecondary;
      case "WHITE":
        return buttonStyles.standardButtonWhite;
    }
  };
  return (
    <button
      disabled={disable}
      onClick={onClick}
      className={`${buttonStyles.standardButton} ${getButtonClassBasedOnVariant()} ${classes ? classes : null}`}
      style={{ width: width }}
    >
      {children}
      {icon}
    </button>
  );
};

export default StandardButton;
