import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getKPICollection } from "./action";
import { Dashboard } from "../../types/kpi";

export interface KPICollectionSlice {
  kpiData?: Dashboard;
  comepareWithNexus: boolean;
}

const initialState: KPICollectionSlice = {
  kpiData: undefined,
  comepareWithNexus: false,
};

const kpiCollectionSlice = createSlice({
  name: "kpi-collection",
  initialState,
  reducers: {
    clearKpitData(state) {
      delete state.kpiData;
    },
    setCompareWithNexus(state, action: PayloadAction<boolean>) {
      state.comepareWithNexus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getKPICollection.pending, (state) => {
      delete state.kpiData;
    });
    builder.addCase(getKPICollection.fulfilled, (state, action) => {
      // Todo Upate
      state.kpiData = action.payload;
    });
    builder.addCase(getKPICollection.rejected, (state) => {
      // Todo add error
    });
  },
});

export const { clearKpitData, setCompareWithNexus } =
  kpiCollectionSlice.actions;

export default kpiCollectionSlice.reducer;
