import { useEffect, useRef, useState } from "react";
import { Employee, WorkTask } from "../../types/ganttChart";
import {
  addFilter,
  addItemToSearchHistory,
  clearFilters,
  clearHistory,
  SearchItem,
  setHighlightedTasks,
} from "../../store/search";
import ColouredRoundWrapper from "../UI/ColouredRoundWrapper";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as XIcon } from "../../assets/icons/black_x.svg";
import searchStyles from "../../styles/search.module.css";
import labels from "../../utils/labels";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import SearchResult from "./SearchResult";
import GreyOpacityText from "../UI/textFields/GreyOpacityText";
import SearchItemChip from "./SearchItemChip";
import SearchIndex from "../../service/SearchIndex";

const SearchBar = ({
  employees,
  unplannedTasks,
}: {
  employees: Employee[];
  unplannedTasks: WorkTask[];
}) => {
  const [input, setInput] = useState("");
  const [suggestions, setSuggestions] = useState<SearchItem[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLUListElement>(null);
  const searchIndex = new SearchIndex([
    ...employees.flatMap((employee) => employee.tasks.workTasks),
    ...unplannedTasks,
  ]);
  const dispatch = useAppDispatch();

  const { appliedFilters, highlightedTaskIds, history } = useAppSelector(
    (state) => state.searchSlice
  );
  const selectedGanttChartTab = useAppSelector(
    (state) => state.ganttChartSlice.selectedGanttChartTab
  );
  const fetchSuggestions = () => {
    if (input.length > 0) {
      const results = searchIndex.suggest(input, appliedFilters);
      setSuggestions(results);
      setIsOpen(results.length > 0);
    } else {
      setSuggestions([]);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    fetchSuggestions();
  }, [input, selectedGanttChartTab]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      const highlighted = history.flatMap((item) => item.ids);
      dispatch(setHighlightedTasks(highlighted));
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const handleSuggestionClick = (suggestion: SearchItem) => {
    dispatch(setHighlightedTasks(suggestion.ids));
    dispatch(addFilter(suggestion));
    dispatch(addItemToSearchHistory(suggestion));
    setInput("");
    setIsOpen(false);
  };

  return (
    <div className="relative" style={{ width: 640 }}>
      <ColouredRoundWrapper bgColor="var(--col-grey-1)" padding width={"100%"}>
        <SearchIcon />
        <div className="flex flex-row">
          {appliedFilters.map((searchItem, index) => (
            <SearchItemChip searchResult={searchItem} key={index} />
          ))}
        </div>
        <input
          ref={inputRef}
          type="text"
          value={input}
          onChange={handleInputChange}
          placeholder={labels.searchPrompt}
          className={`bg-transparent ${searchStyles.searchInputField} w-[100%] focus:outline-none`}
          style={{ height: "100%" }}
        />
        {appliedFilters.length > 0 && (
          <div
            className="min-w-[14px] cursor-pointer"
            onClick={() => {
              setInput("");
              dispatch(clearFilters());
              dispatch(clearHistory());
              dispatch(setHighlightedTasks([]));
            }}
          >
            <XIcon width={14} height={14} />
          </div>
        )}
      </ColouredRoundWrapper>
      {isOpen && suggestions.length > 0 && (
        <ul ref={dropdownRef} className={searchStyles.searchSugeestionsWrapper}>
          <div className="flex w-[100%]">
            <GreyOpacityText size={"MEDIUM"}>Resultater</GreyOpacityText>
          </div>

          {suggestions.map((suggestion, index) => (
            //Use SearchResult.tsx - I will style it later
            <SearchResult
              key={index}
              result={suggestion}
              onClick={() => handleSuggestionClick(suggestion)}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
