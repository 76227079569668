import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import allowedPaths from "../utils/paths";

export const usePathCheck = () => {
  const location = useLocation();
  const [isKpiPage, setIsKpiPage] = useState(false);

  useEffect(() => {
    setIsKpiPage(location.pathname === allowedPaths.overview);
  }, [location.pathname, allowedPaths.overview]);

  return isKpiPage;
};
