import { useState, useEffect } from "react";

export type TimeAgo = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

export const calculateTimeAgo = (
  dateString: string | undefined | null
): TimeAgo | null => {
  if (!dateString) return null;

  const date = new Date(dateString);
  const now = new Date();

  let delta = Math.floor((now.getTime() - date.getTime()) / 1000);

  const days = Math.floor(delta / (24 * 3600));
  delta -= days * 24 * 3600;

  const hours = Math.floor(delta / 3600);
  delta -= hours * 3600;

  const minutes = Math.floor(delta / 60);
  delta -= minutes * 60;

  const seconds = delta;

  return { days, hours, minutes, seconds };
};

export const useTimeAgo = (
  dateString: string | undefined | null
): TimeAgo | null => {
  const [timeAgo, setTimeAgo] = useState<TimeAgo | null>(() =>
    calculateTimeAgo(dateString)
  );

  useEffect(() => {
    if (!dateString) {
      setTimeAgo(null);
      return;
    }
    setTimeAgo(calculateTimeAgo(dateString));

    const interval = setInterval(() => {
      setTimeAgo(calculateTimeAgo(dateString));
    }, 1000);

    return () => clearInterval(interval);
  }, [dateString]);

  return timeAgo;
};

export type TimeUnit = keyof TimeAgo;

// Order of precedence from most to least significant
const TIME_UNITS: TimeUnit[] = ["days", "hours", "minutes", "seconds"];

function getBestTimeUnit(timeAgo?: TimeAgo): TimeUnit {
  if (!timeAgo) return "seconds";
  return TIME_UNITS.find((unit) => timeAgo[unit] > 0) ?? "seconds";
}

export function getBestTimeDisplay(timeAgo: TimeAgo): {
  unit: TimeUnit;
  value: number;
} {
  const unit = getBestTimeUnit(timeAgo);
  return {
    unit,
    value: timeAgo[unit],
  };
}
