import { useEffect, useRef, useState } from "react";
import { WorkTask } from "../../types/ganttChart";
import labels from "../../utils/labels";
import VisitHoursTimeline from "./VisitHoursTimeline";
import VisitHoursListItem from "./VisitHoursListItem";
import drawerStyles from "../../styles/drawer.module.css";
import DrawerBlock from "./DrawerBlock";

const VisitHoursWrapper = ({ task }: { task: WorkTask }) => {
  const visitHoursWrapper = useRef<HTMLDivElement>(null);
  const [svgWidth, setSvgWidth] = useState<number>();
  useEffect(() => {
    if (visitHoursWrapper) setSvgWidth(visitHoursWrapper.current?.clientWidth);
  }, [visitHoursWrapper]);
  return (
    <DrawerBlock title={labels.visitHours}>
      <div
        ref={visitHoursWrapper}
        className={drawerStyles.visitHoursListWrapper}
      >
        {svgWidth && (
          <section
            style={{ width: svgWidth - 2 * 24, marginLeft: 24 }}
            className={drawerStyles.visitHoursBody}
          >
            <VisitHoursTimeline svgWidth={svgWidth - 24} task={task} />
            <div className="gap-1 flex flex-col">
              <VisitHoursListItem
                start={task.start}
                end={task.end}
                label="Planlagt"
                bgColor={"var(--col-lavendar-dark)"}
                borderColor={"var(--col-lavendar-dark)"}
                borderType="SOLID"
              />
              <VisitHoursListItem
                start={task.preferredEarliestStart}
                end={task.preferredLatestEnd}
                label="Foretrukne tid"
                bgColor={"var(--col-lavendar-light)"}
                borderColor={"var(--col-lavendar)"}
                borderType="SOLID"
              />
              <VisitHoursListItem
                start={task.earliestStart}
                end={task.latestEnd}
                label="Tilladt tid"
                bgColor={"var(--col-grey-2)"}
                borderColor={"var(--col-grey-4)"}
                borderType="DASHED"
              />
            </div>
          </section>
        )}
      </div>
    </DrawerBlock>
  );
};

export default VisitHoursWrapper;
