import labels from "./labels";
import { Violation, WorkTask } from "../types/ganttChart";

const getViolationStrings = (task: WorkTask): string[] => {
  const violationStrings = task.violations.map(
    (violationItem) => violationItem["name"]
  );

  return violationStrings.map((violationString) =>
    getViolationMessagesFromViolationName(violationString)
  );
};

export const getViolationMessagesFromViolationName = (violation: string) => {
  switch (violation) {
    case "SKILL":
      return labels.violationSkill;
    case "DEPENDENCY":
      return labels.violationDependency;
    case "DEPENDENCY_MIN_OVERLAP":
      return labels.violationDependency;
    case "DEPENDENCY_SAME_EMPLOYEE":
      return labels.violationDependencySameEmployee;
    case "OVERDUE":
      return labels.violationOverdue;
    case "OVERTIME":
      return labels.violationOvertime;
    case "TRAVEL_TOTAL_DISTANCE":
      return labels.violationTravelTotalDistance;
    case "TRAVEL_TRIP_DISTANCE":
      return labels.violationTravelTripDistance;
    case "TRAVEL_FIRST_LAST_TRIP_DISTANCE":
      return labels.violationTravelFirstLastTripDistance;
    case "BLOCKED_EMPLOYEE":

    default:
      return violation;
  }
};

export const getTransportationViolationMessagesFromViolationName = (
  violation: Violation
) => {
  switch (violation.name) {
    case "TRAVEL_TOTAL_DISTANCE":
      return labels.violationOfTotalTravelDistance;
    case "TRAVEL_TRIP_DISTANCE":
      return labels.violationOfTravelTripDistance;
    case "TRAVEL_FIRST_LAST_TRIP_DISTANCE":
      return labels.violationOfFirstLastTripDistance;
  }
};

export class RuleConcordanceMessageFactory {
  /**
   * Loops through the task dependencies and violations and generates the requisite messages
   */
  static getMessages = (
    task: WorkTask,
    includeViolations: boolean = true
  ): string[] => {
    // test if mapping to both dependent and prerequisite

    const messages: string[] = [];

    for (const dependency of task.taskDependencies) {
      if (dependency.maxDelayTime && dependency.maxDelayAnchor)
        messages.push(
          labels.maxDelayTimeViolationMessage(
            dependency.maxDelayTime.toString(),
            dependency.maxDelayAnchor as "START" | "END"
          )
        );
      if (dependency.minDelayTime && dependency.minDelayAnchor)
        messages.push(
          labels.minDelayTimeViolationMessage(
            dependency.minDelayTime.toString(),
            dependency.minDelayAnchor as "START" | "END"
          )
        );

      if (dependency.minOverlap) {
        messages.push(
          labels.dependencyMinOverlapViolationMessage(
            dependency.minOverlap.toString()
          )
        );
      }

      if (dependency.sameEmployee)
        messages.push(labels.sameEmployeeViolationMessage);

      if (dependency.startRightAfter)
        messages.push(labels.startRightAfterViolationMessage);

      if (dependency.sameStartTime)
        messages.push(labels.sameStartViolationMessage);
    }

    return messages;
  };
  getViolationMessages = (task: WorkTask): string[] => {
    // Get any violation messages if necessary
    const violationMessages = task.violations
      .filter(
        (violation) =>
          // Filter out min overlap violations since this is handled by the minOverlap parameter in the dependency object
          "name" in violation && violation.name !== "DEPENDENCY_MIN_OVERLAP"
      )
      .map((violation) => {
        switch (violation.name) {
          case "SKILL":
            return labels.violationSkill;
          case "OVERDUE":
            return labels.violationOverdue;
          case "OVERTIME":
            return labels.violationOvertime;
          default:
            return labels.unspecifiedViolationMessage;
        }
      });

    return violationMessages;
  };
}

export default getViolationStrings;
