import { menuItemWithIcon } from "../../types/common";
import { ReactComponent as OverviewIcon } from "../../assets/icons/overview.svg";
import { ReactComponent as GanttChartIcon } from "../../assets/icons/gantt-chart.svg";
import NavgationListItem from "./NavigationListItem";
import navigationStyles from "../../styles/navigation.module.css";
// import { useAppDispatch, useAppSelector } from "../../store/hooks";

const PageNavigation = () => {
  // const dispatch = useAppDispatch();
  // const { selectedActiveNexusFilter, selectedNexusFilterDate } = useAppSelector(
  //   (state) => state.nexusFiltersSlice
  // );

  // To do - this needs to be updated with actual content. This is just for show.
  const pageNavigationMenu: menuItemWithIcon[] = [
    {
      icon: <OverviewIcon width={16} height={16} />,
      title: "Oversigt",
      redirectUrl: "/overview",
    },
    {
      icon: <GanttChartIcon width={16} height={16} />,
      title: "Tidsplan",
      redirectUrl: "/ganttChart",
    },
  ];
  return (
    <section className={navigationStyles.navigationList}>
      <div className={navigationStyles.navigationListMenuWrapper}>
        {pageNavigationMenu.map((menuItem, index) => (
          <NavgationListItem menuItem={menuItem} key={index} />
        ))}
      </div>
    </section>
  );
};

export default PageNavigation;
