import ganttChartService from "../../../service/ganttChartService";
import { useAppSelector } from "../../../store/hooks";
import { Employee } from "../../../types/ganttChart";
import { ganttChartVerticalValues } from "../../../utils/ganttChartValues";
import VerticalOperationalTask from "./VerticalOperationalTasks";
import VerticalGanttChartTransportationTask from "./VerticalGanttChartTransportationTask";
import VerticalWorkTask from "./VerticalWorkTask";

const VerticalGanttChartTaskWrapper = ({
  xPosition,
  employee,
}: {
  xPosition: number;
  employee: Employee;
}) => {
  const {
    // selectedGanttChartTab,
    verticalColumnWidth,
    // clickedTaskDependencies,
  } = useAppSelector((state) => state.ganttChartSlice);
  const { timelineRange, pixelPerMinute } = useAppSelector(
    (state) => state.timelineSlice
  );

  const getYPosition = (startTime: string) => {
    return ganttChartVerticalValues.findTaskYPosition(
      timelineRange!,
      pixelPerMinute,
      startTime
    );
  };

  const getHeight = (startTime: string, endTime: string) => {
    return (
      ganttChartService.timeDifferenceInMinutes(startTime, endTime) *
      pixelPerMinute
    );
  };

  return (
    <>
      <>
        {employee.tasks.workTasks.map((task, index) => (
          <VerticalWorkTask
            employee={employee}
            key={"nexus-worktask" + index}
            xPosition={xPosition}
            task={task}
            yPosition={getYPosition(task.start)}
            width={ganttChartVerticalValues.getTaskWidth(verticalColumnWidth)}
            height={getHeight(task.start, task.end)}
          />
        ))}
        {employee.tasks.operationalTasks.map((task, index) => (
          <VerticalOperationalTask
            employee={employee}
            key={"nexus-operationaltask" + index}
            xPosition={xPosition}
            task={task}
            yPosition={getYPosition(task.start)}
            width={ganttChartVerticalValues.getTaskWidth(verticalColumnWidth)}
            height={getHeight(task.start, task.end)}
          />
        ))}

        {employee.tasks.transportationTasks.map((task, index) => (
          <VerticalGanttChartTransportationTask
            employee={employee}
            key={"nexus-transportationtask" + index}
            xPosition={xPosition}
            task={task}
            yPosition={getYPosition(task.start)}
            transportationType={employee.transportationType}
            width={ganttChartVerticalValues.getTaskWidth(verticalColumnWidth)}
          />
        ))}
      </>
    </>
  );
};

export default VerticalGanttChartTaskWrapper;
