import GreyOpacityText from "../UI/textFields/GreyOpacityText";
import TinyDivider from "../UI/TinyDivider";
import headerStyles from "../../styles/header.module.css";
import { useAppSelector } from "../../store/hooks";
import labels from "../../utils/labels";

const HeaderKPIList = () => {
  const { ganttChartData } = useAppSelector(
    //selectedGanttChartTab
    (state) => state.ganttChartSlice
  );

  return (
    <div className={headerStyles.kpiList}>
      <>
        <div className={headerStyles.kpiList__group}>
          <GreyOpacityText key={labels.employees.toLowerCase()} size="SMALL">
            {ganttChartData?.generalGanttChartInfo.kpis.employees}{" "}
            {labels.employees.toLowerCase()}
          </GreyOpacityText>
          <GreyOpacityText key={labels.hours.toLowerCase()} size="SMALL">
            {ganttChartData?.generalGanttChartInfo.kpis.employeeWorkHours}{" "}
            {labels.hours.toLowerCase()}
          </GreyOpacityText>
        </div>
        <TinyDivider colorVariant="PRIMARY"></TinyDivider>
        <div className={headerStyles.kpiList__group}>
          <>
            <GreyOpacityText key={labels.visit.toLowerCase()} size="SMALL">
              {ganttChartData?.generalGanttChartInfo.kpis.visits}{" "}
              {labels.visit.toLowerCase()}
            </GreyOpacityText>
            <GreyOpacityText
              key={`visit_${labels.hours.toLowerCase()}`}
              size="SMALL"
            >
              {ganttChartData?.generalGanttChartInfo.kpis.visitTotalHours}{" "}
              {labels.hours.toLowerCase()}
            </GreyOpacityText>
          </>
        </div>
      </>
    </div>
  );
};

export default HeaderKPIList;
