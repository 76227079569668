import { WebSocketMessagePayload } from "../../hooks/WebSocketProvider";

export const latestMessageIsOfType = <T extends WebSocketMessagePayload>(
  messageTypes: T["bridgeMessageType"][],
  messages?: WebSocketMessagePayload[],
): boolean => {
  return (
    !!messages &&
    messages.length > 0 &&
    messageTypes.includes(messages[messages.length - 1].bridgeMessageType)
  );
};

export const messageStreamIncludesType = <T extends WebSocketMessagePayload>(
  messageType: T["bridgeMessageType"],
  messages?: WebSocketMessagePayload[],
) =>
  !!messages &&
  messages.length > 0 &&
  messages.some((msg) => msg.bridgeMessageType === messageType);

export const decodeJobId = (
  jobId: string,
): { jobId: string; filter: string; date: string } => {
  const [, filter, date] = jobId.split("_");
  return { jobId, filter, date };
};

export const findJobIdByFilterAndDate = (
  jobs: Record<string, WebSocketMessagePayload[]>,
  filter: string | number,
  date: string,
): string | undefined => {
  return Object.keys(jobs).find((id) => {
    const decoded = decodeJobId(id);
    return (
      filter.toString() === decoded.filter.toString() && date === decoded.date
    );
  });
};
