import { HoverTaskType } from "../../../types/ganttChart";
import hoverBoxStyle from "../../../styles/hover.module.css";
import timelineService from "../../../service/timelineService";

const HoverDurationBox = ({
  type,
  duration,
}: {
  type: HoverTaskType;
  duration: string;
}) => {
  const getBackgroundColor = () => {
    switch (type) {
      case "WORKTASK":
        return "var(--col-lavendar-dark)";
      case "TRANSPORTATION_TASK":
        return "var(--col-grey-4)";
        case "OPERATIONAL":
          return "var(--col-taxi-yellow)";
    }
  };

  return (
    <div
      className={hoverBoxStyle.duration}
      style={{ backgroundColor: getBackgroundColor() }}
    >
      {timelineService.returnDurationInMinutes(duration)}
    </div>
  );
};

export default HoverDurationBox;
