import dividerStyles from "../../styles/UI/dividers.module.css";

type ColorVariant = "WHITE" | "PRIMARY";

const TinyDivider = ({ colorVariant }: { colorVariant: ColorVariant }) => {
  return (
    <div
      className={`${dividerStyles.tinyDivider} ${colorVariant === "WHITE" ? dividerStyles.tinyDivider__white : dividerStyles.tinyDivider__primary}`}
    ></div>
  );
};

export default TinyDivider;
