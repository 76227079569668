import { useAppDispatch } from "../../store/hooks";
import { closeDrawer } from "../../store/drawer";
import drawerStyles from "../../styles/drawer.module.css";

const Drawer = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const dispatch = useAppDispatch();
  return (
    <article
      className={drawerStyles.transparantOverlay}
      onClick={() => {
        dispatch(closeDrawer());
      }}
    >
      <div
        className={drawerStyles.drawer}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </article>
  );
};

export default Drawer;
