import StandardButton from "../UI/buttons/StandardButton";
import { ReactComponent as UploadIcon } from "../../assets/icons/black-upload.svg";
import api, { API_PATHS } from "../../service/api";
import { useAppDispatch } from "../../store/hooks";
import { setNotification } from "../../store/toastNotifications";

const SendToNexus = ({
  disable,
  idScenario,
}: {
  disable: boolean;
  idScenario: number;
}) => {
  const dispatch = useAppDispatch();
  const sendToNexus = async () => {
    try {
      const body = {
        idScenario: idScenario,
      };
      const response = await api.post<any>(API_PATHS.postNexusPlan, body);
    } catch {
      dispatch(
        setNotification({
          message: "Vi kunne ikke sende planen til Nexus",
          type: "send_to_nexus",
        })
      );
    }
  };
  return (
    <StandardButton
      onClick={() => sendToNexus()}
      variant="SECONDARY"
      disable={disable}
      icon={<UploadIcon />}
    >
      Send plan til Nexus
    </StandardButton>
  );
};

export default SendToNexus;
