import { cloneElement, ReactElement } from "react";

class iconUtil {
  static updateIconColor = (
    parentContainer: HTMLElement,
    iconColor: string
  ): void => {
    const paths = parentContainer.querySelectorAll("path");

    if (paths) {
      paths.forEach((path) => {
        // Check if any path element has a stroke attribute
        const hasStrokeAttribute = path.getAttribute("stroke");

        if (hasStrokeAttribute) {
          // If any path element has a stroke attribute and iconColor is provided, set the stroke color
          path.setAttribute("stroke", iconColor);
        } else {
          // If no path element has a stroke attribute, then fill color
          path.setAttribute("fill", iconColor);
        }
      });
    }
  };
}
export default iconUtil;
