import { FullTimelineRange } from "../types/timeline";

class dateUtil {
  // needs to take date as props.
  static generateTimelineData(timelineRange: FullTimelineRange) {
    // Initialize an array to store the result
    let dateStrings = [];
    // Loop through the hours of the day
    for (let i = 0; i < timelineRange.fullDayHours; i++) {
      // Create timestring for each hour from first hour
      const dateString = this.getISOTimeStringAtSpecificHour(
        new Date(timelineRange.chosenDate),
        timelineRange.firstHourInFullDay + i
      );

      // Add the generate dateString to the array
      dateStrings.push(dateString);
    }
    return dateStrings;
  }

  static getISOTimeStringAtSpecificHour(date: Date, timepoint: number) {
    var hours = Math.floor(timepoint);
    // Set the time
    date.setHours(hours);
    date.setMinutes((timepoint - hours) * 60);
    date.setSeconds(0);
    date.setMilliseconds(0);

    // Convert to ISO string
    var isoStringAtSetHour = date.toISOString();
    return isoStringAtSetHour;
  }

  static getCalendarValueString = (date: Date) => {
    // @tanja todo: introduce moment library to avoid issues with daylight saving time.
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  static getCalendarDate(date: Date) {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "long",
      year: "numeric",
    };
    return date.toLocaleDateString("da-DK", options);
  }
}

export default dateUtil;
