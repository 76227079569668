import { createSlice } from "@reduxjs/toolkit";

type InitialState = {
  interceptorIsReady: boolean;
  tokenSetCount: number;
};

const initialState: InitialState = {
  interceptorIsReady: false,
  tokenSetCount: 0,
};

const apiConfigurationStateSlice = createSlice({
  name: "apiConfigurationState",
  initialState: initialState,
  reducers: {
    setInterceptorIsReady: (state) => {
      state.interceptorIsReady = true;
    },
    /**
     * Used for triggering update logic in places that need a new access token. Because localStorage event listeners isn't a thing
     */
    incrementTokenSetCount: (state) => {
      state.tokenSetCount = state.tokenSetCount + 1;
    },
  },
});

export default apiConfigurationStateSlice.reducer;

export const { setInterceptorIsReady, incrementTokenSetCount } =
  apiConfigurationStateSlice.actions;
