import { SearchItem } from "../../store/search";
import { ReactComponent as BlackArrowRight } from "../../assets/icons/black-arrow-right.svg";

const SearchResult = ({
  result,
  onClick,
}: {
  result: SearchItem;
  onClick: () => void;
}) => (
  <li
    onClick={onClick}
    className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-black w-[100%] p-2.5 gap-2.5 rounded-[50px] bg-[#F9F9F9]"
  >
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <BlackArrowRight height={12} width={12} style={{ marginRight: 10 }} />
      {result.string}
    </div>
  </li>
);

export default SearchResult;
