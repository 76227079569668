import { useEffect, useMemo, useState } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import api, {
  API_PATHS,
  AuthService,
  setupAuthInterceptor,
  setupRefreshTokenInterceptor,
} from "../service/api";
import { useAppDispatch } from "../store/hooks";
import { initiateNexusLandingDataExtraction } from "../store/ganttChart/action";
import {
  setActiveNexusFilter,
  setSelectedNexusFilterDate,
} from "../store/nexusFilters";
import AppInitializationService from "../service/AppInitializationService";

type NexusFilter = {
  id: number;
  idResponsibleUser: number;
  idRuleset: number;
  filter: string;
  filterId: number;
  activatedDateTime: string;
  active: boolean;
  startTime: string;
  endTime: string;
};
type NexusFilterResponse = NexusFilter | { error: string };

const NexusLandingPage = () => {
  const [shouldDisplayError, setShouldDisplayError] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const appInitializationService = useMemo(() => {
    return new AppInitializationService(dispatch);
  }, [dispatch]);

  const hasPassedAuthChallenge = (): boolean => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.has("code");
  };

  const handleInitialLanding = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const filterId = searchParams.get("filterId");
    const date = searchParams.get("date");
    const filterName = searchParams.get("filterName");
    if (!filterId || !date || !filterName) {
      // If these aren't here, there's no reason for us to be on Nexus Landing
      console.error(
        "Navigated to /nexus-landing without filterId and date url params, redirecting to root..."
      );
      navigate("/");
      return;
    }

    await AuthService.performLogin(filterId, date, filterName);
  };

  const sanitizeLocalDate = (date: string): string => date.split("T")[0];

  const startNexusLandingDataExtraction = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const filterId = searchParams.get("filterId");
    const date = searchParams.get("date");
    const filterName = searchParams.get("filterName");

    const sanitizedFilterName = decodeURIComponent(filterName ?? "");
    const sanitizedDate = date!.split("T")[0];

    // Ensure we can create the Nexus filter
    const nexusFilterResponse = await api.post<NexusFilterResponse>(
      API_PATHS.createOrUpdateNexusFilter,
      {
        filterId: filterId,
        shouldDisable: false,
        displayName: sanitizedFilterName,
      }
    );

    if (!(nexusFilterResponse.status === 200)) {
      setShouldDisplayError(true);
      return;
    }
    // Set active filter and date before redirecting
    dispatch(
      setActiveNexusFilter({
        ...nexusFilterResponse.data,
        filterName: sanitizedFilterName,
      })
    );
    dispatch(setSelectedNexusFilterDate(sanitizedDate));
    // We have a filter, queue the data extraction
    const data = nexusFilterResponse.data as NexusFilter;

    dispatch(
      initiateNexusLandingDataExtraction({
        filterId: parseInt(filterId!),
        date: sanitizeLocalDate(date!),
        pondooFilterId: data.id,
        filterName: sanitizedFilterName,
      })
    );

    setTimeout(() => {
      navigate({
        pathname: "/",
        search: createSearchParams({
          filter: data.id.toString(),
          date: sanitizedDate,
        }).toString(),
      });
    }, 2000);
  };

  const handleSuccessfulAuthCheckLanding = async () => {
    const tokens = await AuthService.exchangeCodeForToken();

    if (!tokens) {
      // TODO @Tanja - do we have an error page?
      console.error("Could not authenticate with tokens");
      navigate("/notfound");
      return;
    }

    appInitializationService.configureAppInitialization(
      tokens.accessToken,
      tokens.refreshToken
    );

    await startNexusLandingDataExtraction();
  };

  useEffect(() => {
    if (hasPassedAuthChallenge()) {
      handleSuccessfulAuthCheckLanding();
    } else {
      handleInitialLanding();
    }
  }, []);

  return (
    <div
      className="flex items-center justify-center"
      style={{ width: "100%", height: "100%" }}
    >
      {shouldDisplayError ? (
        <h1>Noget gik galt - var der data på filteret?</h1>
      ) : (
        <img
          src={require("../assets/icons/spinner.gif")}
          alt={"Loading..."}
          style={{ height: 150, width: 150 }}
        />
      )}
    </div>
  );
};

export default NexusLandingPage;
