import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import ganttChartService from "../../../service/ganttChartService";
import {
  Employee,
  TransportationTask,
  TransportationType,
} from "../../../types/ganttChart";
import VerticalGanttChartTransportationIcon from "./VerticalGanttChartTransportationIcon";
import taskStyles from "../../../styles/ganttChart/tasks.module.css";
import {
  hideTaskHoverBox,
  openDrawer,
  showTaskHoverBox,
} from "../../../store/drawer";
import TaskViolationBox from "./TaskViolationBox";
import ViolationChip from "../../UI/ViolationChip";
import { HighlightTasks } from "../../../utils/highlightUtil";
import { setHighlightedTasks } from "../../../store/search";

const VerticalGanttChartTransportationTask = ({
  xPosition,
  yPosition,
  width,
  task,
  transportationType,
  employee,
}: {
  xPosition: number;
  yPosition: number;
  width: number;
  task: TransportationTask;
  transportationType: TransportationType;
  employee: Employee;
}) => {
  const dispatch = useAppDispatch();
  const { verticalColumnWidth } = useAppSelector(
    (state) => state.ganttChartSlice
  );
  const highlightedTaskIds = useAppSelector(
    (state) => state.searchSlice.highlightedTaskIds
  );
  const { pixelPerMinute } = useAppSelector((state) => state.timelineSlice);
  const [taskHeight, setTaskHeight] = useState<number>(0);
  const iconHeight = 16;

  useEffect(() => {
    if (pixelPerMinute) {
      setTaskHeight(
        ganttChartService.timeDifferenceInMinutes(task.start, task.end) *
          pixelPerMinute
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pixelPerMinute]);

  /* Below are all the calculations to draw the red violation boxes, 
  when tasks exceed the employees shift. */
  const [taskExceedsEndOfShift, setTaskExceedsEndOfShift] =
    useState<boolean>(false);
  const [violationHeight, setViolationHeight] = useState<number>();
  const [fullTaskViolation, setFullTaskViolation] = useState<boolean>(false);

  useEffect(() => {
    if (
      employee &&
      new Date(task.end).getTime() > new Date(employee?.shiftEndTime).getTime()
    ) {
      setTaskExceedsEndOfShift(true);
      const violationHeight =
        ganttChartService.timeDifferenceInMinutes(
          employee!.shiftEndTime,
          task.end
        ) * pixelPerMinute;
      setViolationHeight(violationHeight);
      if (violationHeight >= taskHeight) {
        setFullTaskViolation(true);
      }
    }
  }, [pixelPerMinute]);

  return (
    <>
      {verticalColumnWidth && taskHeight && (
        <>
          {
            <foreignObject
              className="relative"
              x={xPosition + 2}
              y={yPosition}
              width={width}
              height={taskHeight}
              onMouseEnter={() => {
                dispatch(
                  showTaskHoverBox({
                    type: "TRANSPORTATION_TASK",
                    task: task,
                    employee: employee,
                  })
                );
              }}
              onMouseLeave={() => {
                dispatch(hideTaskHoverBox());
              }}
            >
              <div
                style={{
                  height: taskHeight,
                  width: width,
                  opacity: HighlightTasks.getOpacity(
                    highlightedTaskIds,
                    task.idTask
                  ),
                }}
                className={taskStyles.transportationTask}
                onClick={() => {
                  dispatch(setHighlightedTasks([]));
                }}
              >
                {iconHeight < taskHeight && (
                  <VerticalGanttChartTransportationIcon
                    type={transportationType}
                  />
                )}
                {task.violations && task.violations.length > 0 && (
                  <ViolationChip height={14} width={14}></ViolationChip>
                )}
              </div>
            </foreignObject>
          }
          {taskExceedsEndOfShift && violationHeight && (
            <TaskViolationBox
              xPosition={xPosition}
              yPosition={yPosition}
              violationHeight={violationHeight}
              height={taskHeight}
              width={width}
              shiftEndTime={employee!.shiftEndTime}
              fullTaskViolation={fullTaskViolation}
            />
          )}
        </>
      )}
    </>
  );
};

export default VerticalGanttChartTransportationTask;
