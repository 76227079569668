import { Link } from "react-router-dom";
import { menuItemWithIcon } from "../../types/common";
import navigationStyles from "../../styles/navigation.module.css";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function NavigationListItem({
  menuItem,
}: {
  menuItem: menuItemWithIcon;
}) {
  const [buttonElement, setButtonElement] = useState<HTMLElement>();
  const [pathName, setPathName] = useState<string>();
  const [selected, setSelected] = useState<boolean>(false);
  const itemId = `menuItem_${menuItem.title}`;
  const location = useLocation();

  useEffect(() => {
    const windowPathName = document.location.pathname;
    const pathName = windowPathName.split("/")[1];
    setPathName(pathName);
    const buttonElement = document.getElementById(itemId);
    if (buttonElement) setButtonElement(buttonElement);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setSelected(pathName === menuItem.redirectUrl.split("/")[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName]);

  useEffect(() => {
    setIconStrokeColor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const setIconStrokeColor = () => {
    const path = buttonElement?.querySelectorAll("path");
    if (path) {
      for (let i = 0; i < path.length; i++)
        path[i].setAttribute(
          "stroke",
          !selected ? "var(--col-black-60)" : "var(--col-black)"
        );
    }
  };

  return (
    <>
      <Link
        id={itemId}
        className={`${navigationStyles.pageNavigationItem} ${selected && navigationStyles.pageNavigationItem__selected}`}
        to={menuItem.redirectUrl}
      >
        {menuItem.icon}
        <p
          className={navigationStyles.pageNavigationItemTitle}
          style={{
            color: selected ? "var(--col-black)" : "var(--col-black-60)",
          }}
        >
          {menuItem.title}
        </p>
      </Link>
    </>
  );
}
