import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { NotFound } from "./pages/NotFound";
import { useEffect, useMemo, useState } from "react";

import Layout from "./pages/Layout";
import GanttChartPage from "./pages/GanttChartPage";
import { AuthService } from "./service/api";
import KPIPage from "./pages/KPIPage";
import NexusLandingPage from "./pages/NexusLandingPage";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import AppInitializationService from "./service/AppInitializationService";
import { hydrateRunningJobs } from "./store/serverAsyncJobs/actions";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "/", element: <GanttChartPage /> },
      { path: "/overview", element: <KPIPage /> },
      { path: "/ganttChart", element: <GanttChartPage /> },
      { path: "/nexus-landing", element: <NexusLandingPage /> },
      { path: "*", element: <NotFound /> },
    ],
  },
]);

const App = () => {
  const [parametersHandled, setParametersHandled] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const isNexusLanding = (): boolean =>
    window.location.pathname.toLowerCase() === "/nexus-landing";
  const interceptorIsReady = useAppSelector(
    (state) => state.apiConfigurationState.interceptorIsReady,
  );

  // Perform on load actions
  const appInitializationService = useMemo(() => {
    return new AppInitializationService(dispatch);
  }, [dispatch]);

  // Synchronize jobs updated via websocket, in case of reload/disconnect etc.
  useEffect(() => {
    if (interceptorIsReady) dispatch(hydrateRunningJobs());
  }, [dispatch, interceptorIsReady]);

  // Tanja
  useEffect(() => {
    if (parametersHandled) return;
    // If this is a Nexus redirect, handle that logic in the separate page component
    if (isNexusLanding()) return;

    const urlParams = new URLSearchParams(window.location.search);
    // These values are set by pondoo-login-platform when redirecting
    const token = urlParams.get("token");
    const refreshToken = urlParams.get("refreshToken");

    if (token && refreshToken) {
      appInitializationService.configureAppInitialization(token, refreshToken);
      setParametersHandled(true);
    } else {
      // If we get here, we probably refreshed the app manually.
      const refreshToken = localStorage.getItem("refresh_token");
      if (!refreshToken) AuthService.redirectToLogin();

      AuthService.refreshConcreteToken(refreshToken!)
        .then((tokens) => {
          if (!tokens) AuthService.redirectToLogin();

          appInitializationService.configureAppInitialization(
            tokens!.accessToken,
            tokens!.refreshToken,
          );
          setParametersHandled(true);
        })
        .catch((err) => {
          AuthService.redirectToLogin();
        });
    }
  }, [parametersHandled]);

  return <RouterProvider router={router} />;
};
export default App;
