import { useEffect, useRef, useState } from "react";
import hoverBoxStyles from "../../../styles/hover.module.css";
import { useAppSelector } from "../../../store/hooks";
import { OperationalTask, WorkTask } from "../../../types/ganttChart";
import HoverBoxTitle from "./HoverBoxTitle";
import HoverTimestamp from "./HoverTimestamp";
import labels from "../../../utils/labels";
import VerticalGanttChartTransportationIcon from "../verticalGanttChart/VerticalGanttChartTransportationIcon";
import HoverDependencyItem from "./HoverDependencyArray";
import { getTransportationViolationMessagesFromViolationName } from "../../../utils/violationUtils";
import ViolationChip from "../../UI/ViolationChip";

const HoverBox = () => {
  const isRun = useRef(false);
  const [xPosition, setXPosition] = useState<number>();
  const [yPosition, setYPosition] = useState<number>();
  const [boundingClientReact, setboundingClientReact] = useState<any>();
  const [boundingWrapperReact, setboundingWrapperReact] = useState<any>();
  const [svgElement, setsvgElement] = useState<any>();
  const [svgWrapper, setsvgWrapper] = useState<any>();
  const [borderRadius, setBorderRadius] = useState<string>();

  const { taskIsHovered } = useAppSelector((state) => state.modalSlice);
  const handleMouseMove = (e: any) => {
    let showAbove = false;
    let showToTheLeft = false;

    // Check if hoverBox is ready
    // Only appears after first iteration
    if (boundingClientReact && document.getElementById("hoverBox")) {
      const hoverBoxElement = document.getElementById("hoverBox");
      const hoverBoxHeight = hoverBoxElement!.clientHeight;
      const hoverBoxWidth = hoverBoxElement!.clientWidth;
      const boundingBottomYCoordinate = boundingWrapperReact.bottom;
      const boundingRightXCoordinate = boundingWrapperReact.right;
      const hoverBoxExceedsAvailableLength =
        boundingBottomYCoordinate - e.clientY - hoverBoxHeight <= 0;
      const hoverBoxIsWiderThanAvailableWidth =
        boundingRightXCoordinate - e.clientX - hoverBoxWidth <= 0;

      if (hoverBoxExceedsAvailableLength && hoverBoxIsWiderThanAvailableWidth) {
        showAbove = true;
        showToTheLeft = true;
        setBorderRadius(
          "var(--spacing-md) var(--spacing-md) 0 var(--spacing-md)"
        );
      } else if (
        hoverBoxExceedsAvailableLength &&
        !hoverBoxIsWiderThanAvailableWidth
      ) {
        showAbove = true;
        showToTheLeft = false;
        setBorderRadius(
          "var(--spacing-md) var(--spacing-md) var(--spacing-md) 0"
        );
      } else if (
        !hoverBoxExceedsAvailableLength &&
        hoverBoxIsWiderThanAvailableWidth
      ) {
        showAbove = false;
        showToTheLeft = true;
        setBorderRadius(
          "var(--spacing-md) 0 var(--spacing-md) var(--spacing-md)"
        );
      } else {
        showAbove = false;
        showToTheLeft = false;
        setBorderRadius(
          "0 var(--spacing-md) var(--spacing-md)  var(--spacing-md)"
        );
      }

      const offSetHeight = showAbove ? -(hoverBoxHeight + 15) : 5;
      const offSetRight = showToTheLeft ? -(hoverBoxWidth + 15) : 5;

      setXPosition(e.clientX - boundingClientReact.left + offSetRight);
      setYPosition(e.clientY - boundingClientReact.top + offSetHeight);
    }

    if (boundingClientReact && !isRun.current) {
      const offSetHeight = showAbove
        ? -(document.getElementById("hoverBox")!.clientHeight + 15)
        : 5;
      const offSetRight = showToTheLeft
        ? -(document.getElementById("hoverBox")!.clientWidth + 15)
        : 5;

      setXPosition(e.clientX - boundingClientReact.left + offSetRight);
      setYPosition(e.clientY - boundingClientReact.top + offSetHeight);
      isRun.current = true;
    }
  };

  useEffect(() => {
    setsvgElement(document.getElementById("vertical-ganttChart-svg"));
    setsvgWrapper(document.getElementById("vertical-ganttChart-svg-wrapper"));
  }, []);

  useEffect(() => {
    if (svgElement) {
      setboundingClientReact(svgElement!.getBoundingClientRect());
    }
    if (svgWrapper) {
      setboundingWrapperReact(svgWrapper!.getBoundingClientRect());
    }
  }, [svgElement, svgWrapper]);

  useEffect(() => {
    if (boundingClientReact) {
      svgElement!.addEventListener("mousemove", handleMouseMove);
    }
    // Cleanup function to remove the event listener
    return () => {
      if (boundingClientReact) {
        svgElement!.removeEventListener("mousemove", handleMouseMove);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boundingClientReact]);

  const getTitle = () => {
    switch (taskIsHovered?.type) {
      case "WORKTASK":
        const workTask = taskIsHovered.task as WorkTask;
        return workTask.client.description
          ? workTask.client.description
          : workTask.description;
      case "TRANSPORTATION_TASK":
        // const icon = (
        //   <VerticalGanttChartTransportationIcon
        //     color="white"
        //     type={taskIsHovered.employee!.transportationType}
        //   />
        // );
        return "Transport";
      case "OPERATIONAL":
        const operationalTask = taskIsHovered.task as OperationalTask;
        return operationalTask.description;
      default:
        return "";
    }
  };

  const getIcon = () => {
    if (taskIsHovered?.type === "TRANSPORTATION_TASK") {
      return (
        <VerticalGanttChartTransportationIcon
          color="white"
          type={taskIsHovered.employee!.transportationType}
        />
      );
    }
  };

  const getWorkTask = () => {
    return taskIsHovered?.task as WorkTask;
  };

  const getOperationalTask = () => {
    return taskIsHovered?.task as OperationalTask;
  };

  return (
    <>
      {xPosition && yPosition && taskIsHovered && (
        <>
          <foreignObject x={xPosition} y={yPosition} width={430} height="100%">
            <div
              className={hoverBoxStyles.hoverBox}
              id="hoverBox"
              style={{ borderRadius: borderRadius }}
            >
              <div className={hoverBoxStyles.header}>
                <HoverBoxTitle title={getTitle()} icon={getIcon()} />
                <HoverTimestamp
                  start={taskIsHovered.task.start}
                  end={taskIsHovered.task.end}
                  duration={taskIsHovered.task.duration}
                  type={taskIsHovered.type}
                />
              </div>
              <div className={hoverBoxStyles.body}>
                <p className="flex gap-[10px]">
                  {labels.hoverEmployee}
                  <span className="font-bold">
                    {taskIsHovered.employee?.fullName}
                  </span>
                </p>
                {taskIsHovered.type === "OPERATIONAL" && (
                  <p className="flex flex-col">
                    {labels.details}
                    <span className="font-bold">
                      {getOperationalTask().description}
                    </span>
                  </p>
                )}
                {taskIsHovered.type === "WORKTASK" && (
                  <>
                    <ul>
                      <li key="ydelser">{labels.hoverTasks}</li>
                      {getWorkTask().subTasks &&
                        getWorkTask().subTasks.map((subtask, index) => (
                          <li key={`tasks_${index}`} className="font-bold">
                            {subtask.description}
                          </li>
                        ))}
                    </ul>
                    <ul>
                      <li>{labels.dependencies}</li>
                      {getWorkTask().taskDependencies.map(
                        (dependency, index) => (
                          <HoverDependencyItem
                            dependency={dependency}
                            key={`dependency_${index}`}
                          />
                        )
                      )}
                    </ul>
                  </>
                )}
                {taskIsHovered.type === "TRANSPORTATION_TASK" &&
                  "violations" in taskIsHovered.task &&
                  taskIsHovered.task.violations.length > 0 && (
                    <>
                      <ul className={hoverBoxStyles.hoverBoxList}>
                        {taskIsHovered.task.violations.map(
                          (violation, index) => (
                            <li
                              key={`violation_${index}`}
                              className={hoverBoxStyles.hoverBoxViolationText}
                            >
                              <ViolationChip
                                height={14}
                                width={14}
                              ></ViolationChip>
                              {getTransportationViolationMessagesFromViolationName(
                                violation
                              )}
                            </li>
                          )
                        )}
                      </ul>
                    </>
                  )}
              </div>
            </div>
          </foreignObject>
        </>
      )}
    </>
  );
};

export default HoverBox;
