import buttonStyles from "../../../styles/UI/buttons.module.css";
const TabButton = ({
  value,
  selected,
  onClick,
  full,
}: {
  value: string;
  selected: string;
  onClick: (value: string) => void;
  full?: boolean;
}) => {
  return (
    <button
      onClick={() => onClick(value)}
      className={`${buttonStyles.tabButton} ${
        selected === value
          ? buttonStyles.tabButtonSelected
          : buttonStyles.tabButton
      } ${full ? "w-full" : "w-fit"}`}
    >
      {value}
    </button>
  );
};

export default TabButton;
