import kpiStyles from "../../../styles/UI/kpiPage.module.css";

const KPITextLine = ({
  title,
  value,
  color,
  compare,
  borderColor,
  children,
}: {
  title: string;
  value: string;
  color?: string;
  compare: boolean;
  borderColor?: string;
  children?: React.ReactNode;
}) => {
  return (
    <div className="flex justify-between">
      <div className="flex gap-spacing-sm items-center">
        {(color || borderColor) && (
          <div
            style={{
              minHeight: "16px",
              minWidth: "16px",
              borderRadius: "var(--border-radius-sm)",
              backgroundColor: color ? color : "transparant",
              border: borderColor ? `2px solid ${borderColor}` : "none",
            }}
          />
        )}
        <p className={kpiStyles.textLine}>{title}</p>
      </div>
      <div
        className="flex gap-spacing-md justify-end"
        style={{ marginRight: compare && !children ? "81px" : "" }}
      >
        <p className={kpiStyles.boldTextLine}>{value}</p>
        {children}
      </div>
    </div>
  );
};

export default KPITextLine;
