import { useAppDispatch, useAppSelector } from "../../store/hooks";
import ganttChartStyles from "../../styles/ganttChart/ganttChart.module.css";
import VerticalGanttChartContent from "./verticalGanttChart/VerticalGanttChartContent";
import { useEffect, useRef, useState } from "react";
import useGanttChartPlacement from "../../hooks/useGanttChartPlacement";
import {
  setVerticalColumnWidth,
  setVerticalSvgWidth,
} from "../../store/ganttChart";
import SharedTimelineComponent from "./horizontalGanttChart/header/SharedTimelineComponent";
import VerticalTitleTextRow from "./verticalGanttChart/VerticalTitleTextRow";
import VerticalTextRows from "./verticalGanttChart/KpiHeader/KpiRows";
import ganttChartService from "../../service/ganttChartService";
import VerticalGanttChartSeparationLines from "./verticalGanttChart/VerticalGanttChartSeparationLines";
import HoverBox from "./hover/HoverBox";
import { hideTaskHoverBox } from "../../store/drawer";
import BackgroundColumns from "./verticalGanttChart/BackgroundColumns";
import { zoomInOnVerticalView } from "../../store/timeline";
import { ganttChartVerticalValues } from "../../utils/ganttChartValues";
import KpiRows from "./verticalGanttChart/KpiHeader/KpiRows";

const VerticalGanttWrapper = () => {
  const svgWrapper = useRef<HTMLDivElement>(null);
  const svgTitleRow = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { windowWidth } = useGanttChartPlacement();
  const {
    verticalColumnWidth,
    verticalSvgWidth,
    translateXValueInPercent,
    ganttChartData,
    unplannedColumnWidth,
  } = useAppSelector((state) => state.ganttChartSlice);
  const { pixelsPerHour, timelineRange, distanceFromTop } = useAppSelector(
    (state) => state.timelineSlice
  );
  const highlightedTaskIds = useAppSelector(
    (state) => state.searchSlice.highlightedTaskIds
  );
  const { taskIsHovered } = useAppSelector((state) => state.modalSlice);
  const [containerWidthOffset, setContainerWidthOffset] = useState<number>(0);
  const [svgWrapperWidth, setSvgWrapperWidth] = useState<number>(0);
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const [lastScrollTop, setLastScrollTop] = useState<number>(0);

  useEffect(() => {
    if (timelineRange && pixelsPerHour && svgWrapper.current) {
      svgWrapper.current.scrollTo({
        top:
          new Date(timelineRange.firstVisibleHour).getHours() * pixelsPerHour -
          pixelsPerHour / 2,
        left: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineRange, svgWrapper.current, pixelsPerHour]);

  useEffect(() => {
    if (distanceFromTop && svgWrapper.current) {
      svgWrapper.current.scrollTo({
        top: distanceFromTop,
        left: 0,
      });
    }
  }, [distanceFromTop]);

  useEffect(() => {
    if (svgWrapper.current) {
      const verticalSvgWrapperWidth = svgWrapper.current?.clientWidth;
      setSvgWrapperWidth(verticalSvgWrapperWidth);
    }
  }, [windowWidth, svgWrapper.current?.clientWidth]);

  useEffect(() => {
    if (svgWrapper.current) {
      const verticalSvgWrapperWidth = svgWrapper.current.clientWidth;
      setSvgWrapperWidth(verticalSvgWrapperWidth);
      setContainerWidthOffset(
        svgWrapper.current.offsetWidth - svgWrapper.current.clientWidth
      );
    }
  }, [windowWidth, svgWrapperWidth, verticalColumnWidth]);

  useEffect(() => {
    if (svgWrapper.current && ganttChartData) {
      /* Everytime either the data, the width of the unplanned column or the width of the window changes, 
      we need to find the width of the div surrounding the svg and the number of employees to calculate svgWidth and VerticalColumnWidth.  */
      const defaultSvgWidth = svgWrapper.current.clientWidth;
      const numberOfEmployees = ganttChartData.employees.length;

      const pixelsAvailableInViewForEachEmployeeColumn =
        (defaultSvgWidth -
          unplannedColumnWidth -
          ganttChartVerticalValues.verticalViewHeaderColumn) /
        numberOfEmployees;
      /* If it is possible to fit all employee columns into the view, 
      set the global width of the svg to the size of the defaultWrapper,
       and set the columnWidth as the width available for each.
      Else, we need to expand the svg view to overflow the window
       and each column to be the minimum allowed width for an employeeColumn */
      if (
        pixelsAvailableInViewForEachEmployeeColumn >=
        ganttChartVerticalValues.verticalViewMinColumnWidth
      ) {
        dispatch(setVerticalSvgWidth(defaultSvgWidth));
        dispatch(
          setVerticalColumnWidth(
            (defaultSvgWidth -
              unplannedColumnWidth -
              ganttChartVerticalValues.verticalViewHeaderColumn) /
              numberOfEmployees
          )
        );
      } else {
        dispatch(
          setVerticalSvgWidth(
            unplannedColumnWidth +
              numberOfEmployees *
                ganttChartVerticalValues.verticalViewMinColumnWidth +
              75
          )
        );

        dispatch(
          setVerticalColumnWidth(
            ganttChartVerticalValues.verticalViewMinColumnWidth
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth, ganttChartData, unplannedColumnWidth, svgWrapper]);

  const touchPadZoom = (event: WheelEvent) => {
    if (!event.ctrlKey) return;

    event.preventDefault();
    const { deltaY, clientX, clientY } = event;
    dispatch(zoomInOnVerticalView(-deltaY));
  };

  useEffect(() => {
    const handleScroll = () => {
      if (svgWrapper.current) {
        const scrollTop = svgWrapper.current.scrollTop;
        if (scrollTop > lastScrollTop) {
          setIsSticky(true);
        } else if (scrollTop < lastScrollTop) {
          setIsSticky(false);
        }
        setLastScrollTop(scrollTop); // For Mobile or negative scrolling
      }
    };

    const currentWrapper = svgWrapper.current;
    if (currentWrapper) {
      currentWrapper.addEventListener("scroll", handleScroll);
      currentWrapper.addEventListener("scroll", (event) => {
        dispatch(hideTaskHoverBox());
      });
      // Try the MacOS zoom thing
      currentWrapper.addEventListener("wheel", touchPadZoom);
    }

    return () => {
      if (currentWrapper) {
        currentWrapper.removeEventListener("scroll", handleScroll);
        currentWrapper.removeEventListener("wheel", touchPadZoom);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastScrollTop]);

  const [svgHeight, setSvgHeight] = useState<number>();
  const [wrapperHeight, setWrapperHeight] = useState<number>();

  useEffect(() => {
    if (pixelsPerHour && timelineRange && svgTitleRow.current) {
      setSvgHeight(timelineRange?.fullDayHours * pixelsPerHour);
      setWrapperHeight(
        timelineRange?.fullDayHours * pixelsPerHour +
          svgTitleRow.current.clientHeight
      );
    }
  }, [pixelsPerHour, timelineRange, svgTitleRow]);

  return (
    <>
      {ganttChartData && (
        <div
          style={{
            height: "100%",
            overflowX: "scroll",
            borderTop: "1px solid var(--col-grey)",
            top: "100px",
          }}
        >
          <div className="sticky top-[1px] w-full z-10" ref={svgTitleRow}>
            <VerticalTitleTextRow
              title="medarb."
              employees={ganttChartData.employees}
            />
            <KpiRows employees={ganttChartData.employees} isSticky={isSticky} />
          </div>
          <div
            ref={svgWrapper}
            id="vertical-ganttChart-svg-wrapper"
            style={{
              height: "100%",
              borderTop: "1px solid var(--col-grey)",
              width: "fit-content",
              minWidth: `calc(100% + ${containerWidthOffset}px`,
            }}
            className={`${ganttChartStyles.verticalSvgWrapper} `}
          >
            {verticalSvgWidth && verticalColumnWidth && (
              <>
                <svg
                  id="vertical-ganttChart-svg"
                  width={verticalSvgWidth}
                  height={svgHeight}
                  style={{
                    transform: `translateX(-${translateXValueInPercent * verticalSvgWidth}px)`,
                    transition: "transform 250ms ease",
                  }}
                >
                  <g>
                    {timelineRange && (
                      <BackgroundColumns
                        verticalColumnWidth={verticalColumnWidth}
                        ganttChartData={ganttChartData}
                      />
                    )}

                    <SharedTimelineComponent />
                    <VerticalGanttChartContent />
                    {pixelsPerHour && verticalColumnWidth && (
                      <VerticalGanttChartSeparationLines
                        employees={ganttChartData.employees}
                        verticalColumnWidth={verticalColumnWidth}
                        pixelsPerHour={pixelsPerHour}
                      />
                    )}
                    {taskIsHovered && <HoverBox />}
                  </g>
                </svg>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default VerticalGanttWrapper;
