import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import api, { API_PATHS } from "../../service/api";
import { Job, WebSocketMessagePayload } from "../../hooks/WebSocketProvider";
import { ServerAsyncJobsSlice } from "./index";

/**
 * Jobs exist as asynchronous processes updated via the websocket connection, and only become Job entities once
 * optimization starts. This endpoint facilitates getting the state of async processes expressed as a dictionary mapping
 * jobIds to arrays of events that have taken place on that jobId.
 */
export const hydrateRunningJobs = createAsyncThunk(
  "jobs/hydrateRunningJobs",
  async () => {
    const response = await api.get<Record<string, WebSocketMessagePayload[]>>(
      API_PATHS.websocketJobs,
    );
    return response.data;
  },
);

/**
 * Jobs exist as asynchronous processes updated via the websocket connection, and only become Job entities once
 * optimization starts. This endpoint gets the actual database entities for optimization jobs.
 */
export const hydrateJobEntities = createAsyncThunk(
  "jobs/hydrateJobEntities",
  async () => {
    const response = await api.get<Job[]>(API_PATHS.getLatestOptimizationJobs);
    return response.data;
  },
);

export const hydrateRunningJobsCases = (
  builder: ActionReducerMapBuilder<ServerAsyncJobsSlice>,
) =>
  builder.addCase(hydrateRunningJobs.fulfilled, (state, action) => {
    state.websocketJobs = action.payload;
  });

export const hydrateJobEntitiesCases = (
  builder: ActionReducerMapBuilder<ServerAsyncJobsSlice>,
) =>
  builder.addCase(hydrateJobEntities.fulfilled, (state, action) => {
    state.jobEntities = action.payload;
  });
