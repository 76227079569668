class timestampService {
  static returnTwoDigitTimeString(timeValue: number) {
    if (timeValue < 10) {
      return `0${timeValue}`;
    } else {
      return timeValue.toString();
    }
  }

  static getHoursAndMinutesTimestamp(date: string | Date): string {
    return new Date(date).toLocaleTimeString([], {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  static getRoundedDate(date: Date) {
    let minutes = date.getMinutes();
    let hours = date.getHours();

    if (minutes < 15) date.setMinutes(0);
    else if (minutes < 45) date.setMinutes(30);
    else {
      date.setMinutes(0);
      date.setHours(hours + 1);
    }

    return date;
  }
}

export default timestampService;
