import drawerStyles from "../../styles/drawer.module.css";
import TaskModalTitle from "./DrawerTitle";

const DrawerBlock = ({
  children,
  title,
}: {
  children: React.ReactNode | React.ReactNode[];
  title: string;
}) => {
  return (
    <section className={drawerStyles.drawerBlock} >
      <TaskModalTitle>{title}</TaskModalTitle>
      {children}
    </section>
  );
};

export default DrawerBlock;
