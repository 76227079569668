import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../store/hooks";
import WorkTimeCard from "../components/kpiCards/WorkTime";
import CitizenServiceCard from "../components/kpiCards/CitizenServiceCard";
import KeyNumbersCard from "../components/kpiCards/KeyNumbersCard";
import PlannedTimeCard from "../components/kpiCards/PlannedTimeCard";

export default function KPIPage() {
  const { kpiData, comepareWithNexus } = useAppSelector(
    (state) => state.kpiCollectionSlice
  );

  return (
    <article className="flex w-full gap-[var(--spacing-sm)]">
      {kpiData?.pondooDashboard && (
        <>
          <div className="flex flex-col w-[80%] gap-[var(--spacing-sm)]">
            <>
              <CitizenServiceCard
                kpiList={kpiData.pondooDashboard.plan}
                compare={comepareWithNexus}
                compareKpiList={kpiData.nexusDashboard.plan}
              />
              <div className="flex gap-[var(--spacing-sm)] h-[389px]">
                <>
                  <KeyNumbersCard
                    kpiList={kpiData.pondooDashboard.otherKpi}
                    compare={comepareWithNexus}
                    compareKpiList={kpiData.nexusDashboard.otherKpi}
                  />
                  <PlannedTimeCard
                    kpiList={kpiData.pondooDashboard.plannedTime}
                    compare={comepareWithNexus}
                    compareKpiList={kpiData.nexusDashboard.plannedTime}
                  />
                </>
              </div>
            </>
          </div>
          <WorkTimeCard
            kpiList={kpiData.pondooDashboard.workTime}
            compare={comepareWithNexus}
            compareKpiList={kpiData.nexusDashboard.workTime}
          />
        </>
      )}
    </article>
  );
}
