import toasterStyle from "../../styles/toaster.module.css";
const ToastNotification = ({
  title,
  children,
  removeError,
}: {
  title: string;
  children: React.ReactNode;
  removeError: () => void;
}) => {
  return (
    <div className={toasterStyle.toaster}>
      <div className="flex justify-between">
        <h3 className={toasterStyle.toasterTitle}>{title}</h3>
        <button onClick={() => removeError()}>x</button>
      </div>
      {children}
    </div>
  );
};

export default ToastNotification;
