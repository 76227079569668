import wrapperStyles from "../../styles/UI/wrappers.module.css";

const ColouredRoundWrapper = ({
  children,
  classes,
  bgColor,
  height,
  padding,
  color,
  width,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: {
  children: React.ReactNode | React.ReactNode[];
  classes?: string;
  bgColor: string;
  height?: string;
  padding?: boolean;
  color?: string;
  width?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}) => {
  return (
    <div
      className={`${wrapperStyles.colouredRoundWrapper} ${classes ? classes : null}`}
      style={{
        color: color,
        backgroundColor: bgColor,
        width: width ? width : "fit-content",
        height: height ? height : "40px", //Todo: update to util variable
        padding: padding ? "var(--spacing-sm) var(--spacing-md)" : "none",
      }}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  );
};

export default ColouredRoundWrapper;
