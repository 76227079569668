import { useAppSelector } from "../../../store/hooks";
import { ganttChartVerticalValues } from "../../../utils/ganttChartValues";
import { ReactComponent as ClockIcon } from "../../../assets/icons/clock_red.svg";

const TaskViolationBox = ({
  xPosition,
  yPosition,
  violationHeight,
  height,
  width,
  shiftEndTime,
  fullTaskViolation,
}: {
  xPosition: number;
  yPosition: number;
  violationHeight: number;
  height: number;
  width: number;
  shiftEndTime: string;
  fullTaskViolation: boolean;
}) => {
  const { pixelPerMinute, timelineRange } = useAppSelector(
    (state) => state.timelineSlice
  );
  return (
    <foreignObject
      className="pointer-events-none"
      x={xPosition + 2}
      y={
        violationHeight >= height
          ? yPosition
          : ganttChartVerticalValues.findTaskYPosition(
              timelineRange!,
              pixelPerMinute,
              shiftEndTime
            )
      }
      width={width}
      height={fullTaskViolation ? height : violationHeight}
    >
      <div
        className="h-full pointer-events-none"
        style={{
          borderLeft: "4px solid var(--col-dirt-rose-dark)",
          borderBottom: "1px solid var(--col-dirt-rose-dark)",
          backgroundColor: "var(--col-error-1)",
          borderRadius: fullTaskViolation
            ? "var(--border-radius-sm)"
            : "0 0 var(--border-radius-sm) var(--border-radius-sm)",
        }}
      ></div>
    </foreignObject>
  );
};

export default TaskViolationBox;
