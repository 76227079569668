import { Fragment, useEffect, useState } from "react";
import ganttChartService from "../../../service/ganttChartService";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ganttChartVerticalValues } from "../../../utils/ganttChartValues";
import VerticalWorkTask from "./VerticalWorkTask";
import UnplannedTaskChartPositionAllocationService, {
  GroupedTask,
} from "../../../service/UnplannedTaskChartPositionAllocationService";
import { UnplannedTasksType } from "../../../types/ganttChart";
import { setUnplannedColumnWidth } from "../../../store/ganttChart";

const UnplannedTasks = ({
  unplannedTasks,
}: {
  unplannedTasks: UnplannedTasksType;
}) => {
  const disatch = useAppDispatch();
  const { ganttChartData } = useAppSelector((state) => state.ganttChartSlice);
  const { timelineRange, pixelPerMinute } = useAppSelector(
    (state) => state.timelineSlice
  );
  const [unplanned, setUnplanned] = useState<GroupedTask[]>();
  const [taskWidthPx, setTaskWidthPx] = useState<number>();

  useEffect(() => {
    if (!timelineRange) return;
    // Separate tasks into full-day tasks and shorter tasks which need to be laid out
    const separatedTasks =
      UnplannedTaskChartPositionAllocationService.separateFullDayTasks(
        unplannedTasks.workTasks,
        timelineRange
      );
    const groupedTasks =
      UnplannedTaskChartPositionAllocationService.clusterOverlappingTasks(
        separatedTasks.shorterTasks
      );
    setUnplanned(groupedTasks);
    // Check the largest gangttPosition
    const highestGanttChartPosition = (groupedTasks: GroupedTask[]): number => {
      return Math.max(...groupedTasks.map((task) => task.ganttChartPosition));
    };

    const minColumnWidth =
      (ganttChartVerticalValues.unplannedMinWidth + 1) *
        (highestGanttChartPosition(groupedTasks) + 1) +
      2;

    if (minColumnWidth > ganttChartVerticalValues.unplannedColumnMinWidth) {
      disatch(setUnplannedColumnWidth(minColumnWidth));
      setTaskWidthPx(ganttChartVerticalValues.unplannedMinWidth);
    } else {
      disatch(
        setUnplannedColumnWidth(
          ganttChartVerticalValues.unplannedColumnMinWidth
        )
      );
      const taskWidth =
        (ganttChartVerticalValues.unplannedColumnMinWidth - 1) /
        (highestGanttChartPosition(groupedTasks) + 1);
      setTaskWidthPx(taskWidth);
    }
  }, [unplannedTasks, timelineRange, ganttChartData]);

  return (
    <>
      {ganttChartData &&
        timelineRange &&
        unplanned &&
        unplanned.map((task, index) => (
          <Fragment key={"nexus-unplanned" + index}>
            <VerticalWorkTask
              hideViolations
              id={task.ganttChartPosition}
              xPosition={
                ganttChartVerticalValues.verticalViewHeaderColumn +
                (taskWidthPx! + 1) * task.ganttChartPosition
              }
              task={task}
              yPosition={ganttChartVerticalValues.findTaskYPosition(
                timelineRange!,
                pixelPerMinute,
                task.start
              )}
              width={taskWidthPx!}
              height={
                ganttChartService.timeDifferenceInMinutes(
                  task.start,
                  task.end
                ) * pixelPerMinute
              }
            />
          </Fragment>
        ))}
    </>
  );
};

export default UnplannedTasks;
