import { configureStore, combineReducers } from "@reduxjs/toolkit";
import apiConfigurationState from "./apiConfigurationState";
import ganttChartSlice from "./ganttChart/index";
import dragAndDropSlice from "./dragAndDrop/index";
import modalSlice from "./drawer/index";
import timelineSlice from "./timeline/index";
import nexusFiltersSlice from "./nexusFilters";
import kpiCollectionSlice from "./KPICollection";
import searchSlice from "./search";
import serverAsyncJobsSlice from "./serverAsyncJobs";
import toastNotifications from "./toastNotifications";

const store = configureStore({
  reducer: combineReducers({
    apiConfigurationState,
    serverAsyncJobsSlice,
    ganttChartSlice,
    dragAndDropSlice,
    modalSlice,
    timelineSlice,
    nexusFiltersSlice,
    kpiCollectionSlice,
    searchSlice,
    toastNotifications,
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
