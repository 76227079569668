export class TimeUtil {
  /**
   * Serializes a new Date object to a string in the format "YYYY-MM-DD HH:mm:ss".
   * @returns {string} The serialized date string.
   */
  static dateTimeNowString(): string {
    const now = new Date();
    return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")} ${String(now.getHours()).padStart(2, "0")}:${String(now.getMinutes()).padStart(2, "0")}:${String(now.getSeconds()).padStart(2, "0")}`;
  }

  /**
   * Calculates the minute distance between the current time and a given date string.
   * @param dateString The date string in the format "YYYY-MM-DD HH:mm:ss".
   * @returns {number} The minute distance between the current time and the given date.
   */
  static minutesUntil(dateString: string): number {
    const targetDate = new Date(dateString);
    const now = new Date();
    const minutesUntil = Math.floor(
      (targetDate.getTime() - now.getTime()) / (1000 * 60),
    );
    return minutesUntil;
  }
}
