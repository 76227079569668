import shiwtchtyles from "../../../styles/UI/simpleSwitch.module.css";
import { useEffect, useState } from "react";

export type Placement = "BEFORE" | "ABOVE" | "TAILING";

const Switch = ({
  id,
  classes,
  checked,
  disabled,
  label,
  labelPlacement,
  onChange,
  width,
}: {
  id: string;
  classes?: string;
  checked: boolean;
  disabled?: boolean;
  labelPlacement?: Placement;
  label?: string;
  width?: number; //Should be added, when label is passed in.
  onChange: (id: string) => void;
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    setIsChecked(checked);
  }, []);
  return (
    <label
      className={`${shiwtchtyles.switchWrapper} ${
        labelPlacement === "ABOVE" && "flex-col"
      }`}
      style={{ width: width && width.toString() + "px" }}
    >
      <p>
        {(labelPlacement === "BEFORE" || labelPlacement === "ABOVE") && label}
      </p>
      <div className={shiwtchtyles.switch}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => {
            setIsChecked((prevState) => !prevState);
            onChange(id);
          }}
          disabled={disabled}
        />
        <span
          className={`${shiwtchtyles.slider} ${shiwtchtyles.round} ${
            disabled && shiwtchtyles.disabled
          }`}
        ></span>
        {isChecked ? (
          <span
            className={`${shiwtchtyles.sliderCircleIconLine} ${
              disabled && shiwtchtyles.disabled
            }`}
          ></span>
        ) : (
          <span
            className={`${shiwtchtyles.sliderCircleIconCircle} ${
              disabled && shiwtchtyles.disabled
            }`}
          ></span>
        )}
      </div>
      {labelPlacement === "TAILING" && label}
    </label>
  );
};

export default Switch;
