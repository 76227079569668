import { useAppSelector } from "../../../store/hooks";
import ganttChartStyles from "../../../styles/ganttChart/ganttChart.module.css";
const VerticalGanttChartCenteredCell = ({
  children,
  fullOpacity,
  bgColor,
  width,
}: {
  children: React.ReactNode;
  fullOpacity?: boolean;
  bgColor?: string;
  width: number;
}) => {
  return (
    <>
      <div
        className={ganttChartStyles.centeredCell}
        style={{
          backgroundColor: bgColor ? bgColor : "white",
          opacity: fullOpacity ? 1 : 0.5,
          width: width + "px",
          minWidth: width + "px",
        }}
      >
        {children}
      </div>
    </>
  );
};

export default VerticalGanttChartCenteredCell;
