import { Employee } from "../../../types/ganttChart";
import { ganttChartVerticalValues } from "../../../utils/ganttChartValues";
import { useAppSelector } from "../../../store/hooks";

const VerticalGanttChartSeparationLines = ({
  employees,
  verticalColumnWidth,
  pixelsPerHour,
}: {
  employees: Employee[];
  verticalColumnWidth: number;
  pixelsPerHour: number;
}) => {
  const { timelineRange } = useAppSelector((state) => state.timelineSlice);
  const { unplannedColumnWidth } = useAppSelector(
    (state) => state.ganttChartSlice
  );
  const getXPositionForEmployeeColumns = (index: number) => {
    return (
      ganttChartVerticalValues.verticalViewHeaderColumn +
      unplannedColumnWidth +
      verticalColumnWidth +
      index * verticalColumnWidth
    );
  };

  const getXPositionForUnplannedColumn = () => {
    return (
      ganttChartVerticalValues.verticalViewHeaderColumn + unplannedColumnWidth
    );
  };

  return (
    <>
      {timelineRange && (
        <line
          key={"unplanned"}
          id="column-seperation-line"
          x1={getXPositionForUnplannedColumn()}
          x2={getXPositionForUnplannedColumn()}
          y1={0}
          y2={timelineRange.fullDayHours * pixelsPerHour}
          stroke="var(--col-grey)"
        />
      )}
      {timelineRange &&
        employees.map((_, index) => (
          <line
            key={index}
            id="column-seperation-line"
            x1={getXPositionForEmployeeColumns(index)}
            x2={getXPositionForEmployeeColumns(index)}
            y1={0}
            y2={timelineRange.fullDayHours * pixelsPerHour}
            stroke="var(--col-grey)"
          />
        ))}
    </>
  );
};

export default VerticalGanttChartSeparationLines;
