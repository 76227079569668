class Node<T> {
  children: Map<string, Node<T>>;
  isLeaf: boolean;
  data: T[] = [];

  constructor() {
    this.children = new Map();
    this.isLeaf = false;
  }
}

/**
 * A trie data structure to facilitate autocomplete queries. We index a set of objects by calling .insert() and providing
 * a suitable function to extract a string from the object. Strings are added one character at a time (starting from the beginning) by starting from the root
 * and following children with that particular character until no more children exist. If we still have any string left, we keep adding
 * depth to the tree by adding a node for each remaining character. <br>
 * We can then perform autocomplete queries on the tree by searching with a string; the search proceeds by walking the tree
 * one character at a time until we reach the end of the search string. The autocomplete suggestions are then a depth-first
 * traversal of each child of the node we reached.
 * This gives us an indexing complexity of O(N*logM) and a search complexity of O(log M) where N is the size of the set of items and M is the length of the input string
 */
class PrefixTrie {
  private readonly root: Node<any>;
  private readonly caseSensitive: boolean;

  constructor(caseSensitive: boolean = true) {
    this.root = new Node();
    this.caseSensitive = caseSensitive;
  }

  insert<T>(item: T, itemToStringMapper: (item: T) => string) {
    const word = this.caseSensitive
      ? itemToStringMapper(item)
      : itemToStringMapper(item).toLowerCase();

    let current = this.root;

    // Insert item from the root
    for (const char of word) {
      if (!current.children.has(char)) {
        current.children.set(char, new Node());
      }
      current = current.children.get(char)!;
    }
    current.isLeaf = true;
    current.data.push(item);
  }

  searchItemsByPrefix<T>(
    prefix: string,
    nodeToItemMapper: (node: Node<T>) => T,
  ): T[] {
    const results: T[] = [];

    prefix = this.caseSensitive ? prefix : prefix.toLowerCase();

    const startNode = this.findStartSearchNode(prefix);
    if (startNode)
      this.depthFirstSearch(
        startNode as Node<T>,
        prefix,
        results,
        nodeToItemMapper,
      );

    return results;
  }

  private findStartSearchNode<T>(prefix: string): Node<T> | null {
    let current = this.root;
    for (const char of prefix) {
      if (!current.children.has(char)) return null;

      current = current.children.get(char)!;
    }

    return current;
  }

  private depthFirstSearch<T>(
    node: Node<T>,
    currentPrefix: string,
    results: T[],
    nodeToItemMapper: (node: Node<T>) => T,
  ) {
    if (node.isLeaf) results.push(nodeToItemMapper(node));

    for (const [char, childNode] of node.children)
      this.depthFirstSearch(
        childNode,
        currentPrefix + char,
        results,
        nodeToItemMapper,
      );
  }
}

export default PrefixTrie;
