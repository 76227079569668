import { TaskDependency, WorkTask } from "../../types/ganttChart";
import drawerStyles from "../../styles/drawer.module.css";
import GreyOpacityText from "../UI/textFields/GreyOpacityText";
import timestampService from "../../service/timestampService";
import labels from "../../utils/labels";
import { ReactComponent as WarningIcon } from "../../assets/icons/warning.svg";
import { DependencyMessageFactory } from "../../utils/dependencyUtil";

const TaskDependencyBox = ({
  dependency,
  violation,
}: {
  dependency?: TaskDependency;
  violation: boolean;
}) => {
  const className = `${drawerStyles.taskDependency} ${violation && drawerStyles.hasViolations}`;
  return (
    <section className={className}>
      {violation && (
        <div className="flex gap-[--spacing-sm] items-center mb-2">
          <WarningIcon width={14} height={14} />
          <p>Regelbrud</p>
        </div>
      )}
      <div className={drawerStyles.taskInfoContainer}>
        <h5 className={drawerStyles.employeeName}>
          {dependency?.employeeName ? dependency?.employeeName : "Uplanlagt"}
        </h5>
        <div className={drawerStyles.taskTimeContainer}>
          <div className={drawerStyles.taskTimeContainerTimeColumn}>
            <GreyOpacityText size="MEDIUM">
              {labels.startTimeColumnLabel}
            </GreyOpacityText>
            <p>
              {dependency?.start &&
                timestampService.getHoursAndMinutesTimestamp(dependency?.start)}
            </p>
          </div>
          <div className={drawerStyles.taskTimeContainerTimeColumn}>
            <GreyOpacityText size="MEDIUM">
              {labels.endTimeColumnLabel}
            </GreyOpacityText>
            <p>
              {dependency?.end &&
                timestampService.getHoursAndMinutesTimestamp(dependency?.end)}
            </p>
          </div>
        </div>
      </div>
      <ul className="list-none">
        {DependencyMessageFactory.getDependencyMessages(dependency!).map(
          (message) => (
            <li>{message}</li>
          )
        )}
      </ul>
    </section>
  );
};

export default TaskDependencyBox;
