import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type SearchItemType = "CITIZEN" | "TASK";

export type SearchItem = {
  string: string;
  itemType: SearchItemType;
  ids: number[];
};

type SearchState = {
  history: SearchItem[];
  appliedFilters: SearchItem[];
  highlightedTaskIds: number[];
};

const initialState: SearchState = {
  history: [],
  appliedFilters: [],
  highlightedTaskIds: [],
};

const MAX_FILTER_SIZE = 3;

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setHighlightedTasks: (state, action: PayloadAction<number[]>) => {
      state.highlightedTaskIds = action.payload;
    },
    removeHighlightedTask: (state, action: PayloadAction<number>) => {
      state.highlightedTaskIds = state.highlightedTaskIds.filter(
        (x) => x !== action.payload
      );
    },
    addItemToSearchHistory: (state, action: PayloadAction<SearchItem>) => {
      state.history.push(action.payload);
    },
    removeItemFromSearchHistory: (state, action: PayloadAction<SearchItem>) => {
      state.history = state.history.filter(
        (item) => !isIdenticalSearchItem(item, action.payload)
      );
    },
    clearHistory: (state, action: PayloadAction) => {
      return { ...state, history: [] };
    },
    addFilter: (state, action: PayloadAction<SearchItem>) => {
      if (state.appliedFilters.length >= MAX_FILTER_SIZE) {
        state.appliedFilters.shift();
      }
      state.appliedFilters.push(action.payload);
    },
    removeFilter: (state, action: PayloadAction<SearchItem>) => {
      const remainingFilters = state.appliedFilters.filter(
        (item) => !isIdenticalSearchItem(item, action.payload)
      );
      state.appliedFilters = remainingFilters;
      state.highlightedTaskIds = remainingFilters.flatMap((item) => item.ids);
    },
    clearFilters: (state, action: PayloadAction) => {
      state.appliedFilters = [];
    },
  },
});

const isIdenticalSearchItem = (
  queryItem: SearchItem,
  comparisonItem: SearchItem
): boolean =>
  queryItem.itemType === comparisonItem.itemType &&
  queryItem.string === comparisonItem.string &&
  queryItem.ids.every((id) => comparisonItem.ids.includes(id));

export const {
  addItemToSearchHistory,
  clearFilters,
  clearHistory,
  addFilter,
  setHighlightedTasks,
  removeItemFromSearchHistory,
  removeFilter,
  removeHighlightedTask,
} = searchSlice.actions;

export default searchSlice.reducer;
