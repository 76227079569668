import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { ServerAsyncJobsSlice } from "./index";
import { initiateGanttChartData } from "../ganttChart/action";
import { decodeJobId, findJobIdByFilterAndDate } from "./helpers";

export const initiateGanttChartDataCase = (
  builder: ActionReducerMapBuilder<ServerAsyncJobsSlice>,
) => {
  /**
   * When we get a jobId from the backend, we create a slot for it in the active jobs
   */
  builder.addCase(initiateGanttChartData.fulfilled, (state, action) => {
    const jobId = action.payload.jobId;
    const { filter, date } = decodeJobId(jobId);
    // Clear any existing job with this filter/date combo
    const existingJobId = findJobIdByFilterAndDate(
      state.websocketJobs,
      filter,
      date,
    );
    if (existingJobId) delete state.websocketJobs[existingJobId];

    state.websocketJobs[jobId] = [];
  });
};
