import { Employee } from "../../../types/ganttChart";
import ganttChartStyles from "../../../styles/ganttChart/ganttChart.module.css";
import { ganttChartVerticalValues } from "../../../utils/ganttChartValues";
import VerticalGanttChartCenteredCell from "./VerticalGanttChartCenteredCell";
import { useAppSelector } from "../../../store/hooks";

const VerticalTitleTextRow = ({
  title,
  employees,
}: {
  title: string;
  employees: Employee[];
}) => {
  const { verticalColumnWidth, unplannedColumnWidth } = useAppSelector(
    (state) => state.ganttChartSlice
  );
  return (
    <div
      className={ganttChartStyles.verticalColumnTextItemsWrapper}
      style={{
        transition: "ease-in",
        position: "sticky",
        top: 0,
      }}
    >
      <div
        className={ganttChartStyles.columnTitleCell}
        style={{
          height:
            ganttChartVerticalValues.verticalViewTextRowHeight.toString() +
            "px",
        }}
      >
        <p>{title}</p>
      </div>
      <VerticalGanttChartCenteredCell
        fullOpacity
        key={`VerticalTitleTextRow-0`}
        width={unplannedColumnWidth}
      >
        <p>Uplanlagte</p>
      </VerticalGanttChartCenteredCell>
      {employees.map((employee, index) => (
        <VerticalGanttChartCenteredCell
          fullOpacity
          key={`VerticalTitleTextRow-${index + 1}`}
          width={verticalColumnWidth}
        >
          <p title={employee.fullName}> {employee.fullName}</p>
        </VerticalGanttChartCenteredCell>
      ))}
    </div>
  );
};

export default VerticalTitleTextRow;
