import { createSlice } from "@reduxjs/toolkit";
import {
  AuxiliaryTask,
  Employee,
  HoverTaskType,
  TransportationTask,
  WorkTask,
} from "../../types/ganttChart";

type TaskHoverBoxAction = {
  payload: {
    type: HoverTaskType;
    task: WorkTask | TransportationTask | AuxiliaryTask;
    employee?: Employee;
  };
};

type SelectedTaskToShow = {
  payload: {
    task: WorkTask,
    employeeName?: string
  }
}

export interface DrawerSlice {
  isDrawerOpen: boolean;
  taskToShow?: {task: WorkTask, employeeName?: string}
  taskIsHovered?: {
    type: HoverTaskType;
    task: WorkTask | TransportationTask | AuxiliaryTask;
    employee?: Employee;
  };
}

const initialState: DrawerSlice = {
  isDrawerOpen: false,
};

const drawerSlice = createSlice({
  name: "gantt-chart",
  initialState,
  reducers: {
    openDrawer(state, action: SelectedTaskToShow) {
      state.isDrawerOpen = true;
      state.taskToShow = action.payload;
    },
    closeDrawer(state) {
      state.isDrawerOpen = false;
      delete state.taskToShow;
    },
    showTaskHoverBox(state, action: TaskHoverBoxAction) {
      state.taskIsHovered = action.payload;
    },
    hideTaskHoverBox(state) {
      delete state.taskIsHovered;
    },
  },
});

export const { openDrawer, closeDrawer, showTaskHoverBox, hideTaskHoverBox } =
  drawerSlice.actions;

export default drawerSlice.reducer;
