import wrapperStyles from "../../../styles/page.module.css";

export default function MainContentWrapper({
  children,
  classes,
}: {
  children: React.ReactNode | React.ReactNode[];
  classes?: string;
}) {
  const classList = `${wrapperStyles.mainWrapper}  ${classes ? classes : null}`;
  return <article className={classList}>{children}</article>;
}
