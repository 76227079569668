import cardStyles from "../../styles/card.module.css";
import MediumHeader from "../UI/textFields/MediumHeader";

const KpiCard = ({
  classes,
  icon,
  title,
  children,
}: {
  classes: string;
  icon: React.ReactNode;
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <div className={`${cardStyles.kpiCard} ${classes}`}>
      <div className={cardStyles.kpiCardHeader}>
        {icon}
        <MediumHeader>{title}</MediumHeader>
      </div>
      {children}
    </div>
  );
};

export default KpiCard;
