import { Dispatch } from "@reduxjs/toolkit";
import { setupAuthInterceptor, setupRefreshTokenInterceptor } from "./api";
import {
  incrementTokenSetCount,
  setInterceptorIsReady,
} from "../store/apiConfigurationState";
import { hydrateJobEntities } from "../store/serverAsyncJobs/actions";

class AppInitializationService {
  private readonly dispatch: Dispatch<any>;

  constructor(dispatch: Dispatch<any>) {
    this.dispatch = dispatch;
  }

  configureAppInitialization = (token: string, refreshToken: string) => {
    localStorage.setItem("access_token", token);
    localStorage.setItem("refresh_token", refreshToken);

    const tokenSetIncrement = () => this.dispatch(incrementTokenSetCount());

    setupAuthInterceptor(token);
    setupRefreshTokenInterceptor(tokenSetIncrement);

    this.dispatch(setInterceptorIsReady());
    this.dispatch(hydrateJobEntities());
  };
}

export default AppInitializationService;
