import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setHighlightedTasks } from "../../../store/search";
import { ganttChartVerticalValues } from "../../../utils/ganttChartValues";

const VerticalShiftColumn = ({
  fill,
  xPosition,
  yPosition,
  height,
}: {
  fill?: string;
  xPosition: number;
  yPosition: number;
  height: number;
}) => {
  const dispatch = useAppDispatch();
  const { verticalColumnWidth } = useAppSelector(
    (state) => state.ganttChartSlice
  );
  const { pixelsPerHour } = useAppSelector((state) => state.timelineSlice);

  return (
    <>
      {verticalColumnWidth && pixelsPerHour && (
        <rect
          style={{ fill: fill }}
          y={yPosition}
          x={xPosition}
          width={
            verticalColumnWidth >=
            ganttChartVerticalValues.verticalViewMinColumnWidth
              ? verticalColumnWidth
              : ganttChartVerticalValues.verticalViewMinColumnWidth
          }
          height={height}
          onClick={() => {
            dispatch(setHighlightedTasks([]));
          }}
        ></rect>
      )}
    </>
  );
};

export default VerticalShiftColumn;
