import ganttChartService from "../../../../service/ganttChartService";
import { useAppSelector } from "../../../../store/hooks";
import ganttCHartValues from "../../../../utils/ganttChartValues";
import TypedRowInLeftSideColumn from "./TypedRowInLeftSideColumn";
import ganttChartStyles from "../../../../styles/ganttChart/ganttChart.module.css";
import useGanttChartPlacement from "../../../../hooks/useGanttChartPlacement";
import LeftSideColumnsHeader from "../header/LeftSideColumnsHeader";
import { Employee } from "../../../../types/ganttChart";

const GanttBoardLeftSideColumns = ({
  employees,
}: {
  employees: Employee[];
}) => {
  const selectedGanttChartTab = useAppSelector(
    (state) => state.ganttChartSlice.selectedGanttChartTab
  );

  const { individualRowHeight, unplannedRowHeight, unplannedRowHeightWrapper } =
    useGanttChartPlacement();

  return (
    <>
      <LeftSideColumnsHeader />
      <ul className={ganttChartStyles.leftSideColumn}>
        <li
          className={`${ganttChartStyles.employeeListItem}`}
          style={{
            width: ganttCHartValues.nameColumnWidthString,
            height: unplannedRowHeightWrapper.toString() + "px",
          }}
        >
          Unplanned
          <div
            className={`${ganttChartStyles.unplannedRow} pattern-stripe `}
            style={{
              height:
                selectedGanttChartTab === "Both"
                  ? unplannedRowHeight * 2
                  : unplannedRowHeight,
            }}
          >
            {(selectedGanttChartTab === "Both" ||
              selectedGanttChartTab === "Optimeret") && (
              <p className={ganttChartStyles.platformField}>Pondoo</p>
            )}
            {(selectedGanttChartTab === "Both" ||
              selectedGanttChartTab === "Original") && (
              <p className={ganttChartStyles.platformField}>Nexus</p>
            )}
          </div>
        </li>
        {employees.map((employee, index) => (
          <li
            key={"employee_" + index}
            className={ganttChartStyles.employeeListItem}
            style={{
              width: ganttCHartValues.nameColumnWidthString,
              height:
                selectedGanttChartTab === "Both"
                  ? (individualRowHeight * 2).toString() + "px"
                  : individualRowHeight.toString() + "px",
            }}
          >
            {employee.fullName}

            {/* Type indicates whether it is a Pondoo or a Nexus row */}
            <div className="flex flex-col items-end w-[180px]">
              {(selectedGanttChartTab === "Optimeret" ||
                selectedGanttChartTab === "Both") && (
                <TypedRowInLeftSideColumn
                  platform="Pondoo"
                  bgColor={ganttChartService.rowColor(
                    "Optimeret",
                    index,
                    selectedGanttChartTab
                  )}
                  height={individualRowHeight}
                ></TypedRowInLeftSideColumn>
              )}
              {(selectedGanttChartTab === "Original" ||
                selectedGanttChartTab === "Both") && (
                <TypedRowInLeftSideColumn
                  platform="Nexus"
                  bgColor={ganttChartService.rowColor(
                    "Original",
                    index,
                    selectedGanttChartTab
                  )}
                  height={individualRowHeight}
                />
              )}
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default GanttBoardLeftSideColumns;
