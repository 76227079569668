/**
 * A singleton implementation that holds a responsibleUserId if returned by the API. Used to properly set Axios interceptors
 */
export class ResponsibleUserService {
    private static instance: ResponsibleUserService;
    private responsibleUserId: string | null = null;

    private constructor() {}

    public static getInstance(): ResponsibleUserService {
        if (!ResponsibleUserService.instance) {
            ResponsibleUserService.instance = new ResponsibleUserService();
        }
        return ResponsibleUserService.instance;
    }

    public setResponsibleUserId(id: string | null): void {
        this.responsibleUserId = id;
    }

    public getResponsibleUserId(): string | null {
        return this.responsibleUserId;
    }
}
