import timestampService from "../../../service/timestampService";
import { HoverTaskType } from "../../../types/ganttChart";
import HoverDurationBox from "./HoverDurationBox";
import hoverBoxStyle from "../../../styles/hover.module.css";

const HoverTimestamp = ({
  start,
  end,
  duration,
  type,
}: {
  start: string;
  end: string;
  duration: string;
  type: HoverTaskType;
}) => {
  return (
    <div className={hoverBoxStyle.timeBlock}>
      <p className={hoverBoxStyle.timestamp}>
        {timestampService.getHoursAndMinutesTimestamp(start)} -
        {timestampService.getHoursAndMinutesTimestamp(end)}
      </p>
      <HoverDurationBox type={type} duration={duration} />
    </div>
  );
};

export default HoverTimestamp;
