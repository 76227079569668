import hoverStyles from "../../styles/hover.module.css";

const StandardHoverBox = ({ text }: { text: string }) => {
  return (
    <div className={hoverStyles.smallHoverBox}>
      <p className="w-full">{text}</p>
    </div>
  );
};

export default StandardHoverBox;
