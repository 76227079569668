import { createAsyncThunk } from "@reduxjs/toolkit";
import api, { API_PATHS } from "../../service/api";

enum actionTypes {
  retrieveKPIs = "retrieveKPIs",
}

type GetDashboardParams = {
  nexusIdSolution: number | undefined;
  pondooIdSolution: number | undefined;
};

export const getKPICollection = createAsyncThunk(
  actionTypes.retrieveKPIs,
  async (params: GetDashboardParams) => {
    const response = await api.get<any>(
      `${API_PATHS.retrieveKPIs}?nexusIdSolution=${params.nexusIdSolution || ''}&pondooIdSolution=${params.pondooIdSolution || ''}`
    );
    return response.data;
  }
);
