import timestampService from "../../service/timestampService";
import drawerStyles from "../../styles/drawer.module.css";

const VisitHoursListItem = ({
  start,
  end,
  bgColor,
  borderColor,
  borderType,
  label,
}: {
  start: string;
  end: string;
  bgColor: string;
  borderColor: string;
  label: string;
  borderType: "SOLID" | "DASHED";
}) => {
  return (
    <div className={drawerStyles.visitHoursListItem}>
      <span className="flex items-center gap-2">
        <div
          style={{
            borderRadius: "5px",
            backgroundColor: bgColor,
            width: "16px",
            height: "16px",
            border: `1px ${borderType === "DASHED" ? "dashed" : "solid"} ${borderColor}`,
          }}
        ></div>
        {label}
      </span>
      ({timestampService.getHoursAndMinutesTimestamp(start)} -
      {timestampService.getHoursAndMinutesTimestamp(end)})
    </div>
  );
};

export default VisitHoursListItem;
