import { createAsyncThunk } from "@reduxjs/toolkit";
import api, { API_PATHS } from "../../service/api";
import { setNotification } from "../toastNotifications/index"; // Import the action

enum actionTypes {
  retrieveNexusFilters = "retrieveNexusFilters",
  retrieveActiveNexusFilters = "retrieveActiveNexusFilters",
  getPlanningDates = "getPlanningDates",
}

export const getNexusFilters = createAsyncThunk(
  actionTypes.retrieveNexusFilters,
  async () => {
    const response = await api.get<any>(API_PATHS.getNexusFilters);
    return response.data;
  }
);

export const getActiveNexusFilters = createAsyncThunk(
  actionTypes.retrieveActiveNexusFilters,
  async () => {
    const response = await api.get<any>(API_PATHS.getActiveNexusFilters);
    return response.data;
  }
);

export const getPlanningDates = createAsyncThunk(
  actionTypes.getPlanningDates,
  async (activeNexusFilterId: number, thunkAPI) => {
    const { dispatch } = thunkAPI;
    try {
      const response = await api.get<any>(
        `${API_PATHS.getCalendarDates}?idActiveNexusFilter=${activeNexusFilterId}`
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        setNotification({
          message: "Failed to retrieve available planning dates.",
          type: "available_planning-dates",
        })
      );
    }
  }
);
