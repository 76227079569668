import labels from '../../utils/labels';
import KPITextLine from '../UI/kpi/KPITextLine';
import KpiCard from './KpiCard';
import { ReactComponent as BriefcaseIcon } from '../../assets/icons/briefcase.svg';
import { useAppSelector } from '../../store/hooks';
import { useEffect, useState } from 'react';
import kpiStyles from '../../styles/UI/kpiPage.module.css';
import { KpiWorkTime } from '../../types/kpi';
import ComparisonChip from '../UI/kpi/ComparisonChip';
import comparisonUtil from '../../utils/comparisonUtil';

const WorkTimeCard = ({
	kpiList,
	compareKpiList,
	compare,
}: {
	kpiList: KpiWorkTime;
	compareKpiList: KpiWorkTime;
	compare: boolean;
}) => {
	const { kpiData } = useAppSelector((state) => state.kpiCollectionSlice);

  const shiftText = kpiList.shiftHours > 0 ? `${kpiList.shiftHours} ${labels.hours}` : `${kpiList.shiftMinutes} ${labels.minutes}`;
  const overtimeText = kpiList.overtimeHours > 0 ? `${kpiList.overtimeHours} ${labels.hours}` : `${kpiList.overtimeMinutes} ${labels.minutes}`;
  const unplannedText = kpiList.unplannedHours > 0 ? `${kpiList.unplannedHours} ${labels.hours}` : `${kpiList.unplannedMinutes} ${labels.minutes}`;
  const shiftScheduleText = kpiList.shiftScheduleHours > 0 ? `${kpiList.shiftScheduleHours} ${labels.hours}` : `${kpiList.shiftScheduleMinutes} ${labels.minutes}`;

	return (
		<KpiCard title="Arbejdstid" icon={<BriefcaseIcon />} classes="w-[30%] max-w-[430px] mb-[25px] h-[705px]">
			<div className="flex flex-col gap-[50px]">
				{kpiData && (
					<div className="flex gap-spacing-xl justify-center mt-[25px]">
						<div className={kpiStyles.graphBars}>
							{kpiData!.pondooDashboard.workTime.overtimePercent > 0 && (
								<div
									style={{
										height: `${kpiData!.pondooDashboard.workTime.overtimePercent}%`,
										backgroundColor: 'var(--col-dirt-rose)',
										border: '2px solid var(--col-dirt-rose-dark)',
									}}
								></div>
							)}
							{kpiData!.pondooDashboard.workTime.shiftPercent > 0 && (
								<div
									className={kpiStyles.individualGraphBars}
									style={{
										height: `${kpiData!.pondooDashboard.workTime.shiftPercent}%`,
										backgroundColor: 'var(--col-mocha-late)',
										border: '2px solid var(--col-mocha-cappucino)',
									}}
								></div>
							)}
						</div>
						<div className={kpiStyles.graphBars}>
							{kpiData!.pondooDashboard.workTime.unplannedPercent > 0 && (
								<div
									className={kpiStyles.individualGraphBars}
									style={{
										height: `${kpiData!.pondooDashboard.workTime.unplannedPercent}%`,
										backgroundColor: 'var(--col-taxi-yellow-light)',
										border: '2px solid var(--col-taxi-yellow)',
									}}
								></div>
							)}

							{kpiData!.pondooDashboard.workTime.shiftSchedulePercent > 0 && (
								<div
									className={kpiStyles.individualGraphBars}
									style={{
										height: `${kpiData!.pondooDashboard.workTime.shiftSchedulePercent}%`,
										backgroundColor: 'var(--col-lavendar-light)',
										border: '2px solid var(--col-lavendar)',
									}}
								></div>
							)}
						</div>
					</div>
				)}
				<div className="flex flex-col gap-spacing-xl">
					<KPITextLine
						compare={compare}
						title={labels.plan}
						value={shiftText}
						color="var(--col-mocha-late)"
						borderColor="var(--col-mocha-cappucino)"
					/>
					<KPITextLine
						compare={compare}
						title={labels.overtime}
						value={overtimeText}
						color="var(--col-dirt-rose)"
						borderColor="var(--col-dirt-rose-dark)"
					>
						{compare && (
							<ComparisonChip
								positiveIndicator="NEGATIVE"
								value={comparisonUtil.getRoundedComparison(
									kpiList.overtimeHours,
									compareKpiList.overtimeHours
								)}
								id={compareKpiList.overtimeHours}
							/>
						)}
					</KPITextLine>
					<KPITextLine
						compare={compare}
						title={labels.unplannedTime}
						value={unplannedText}
						color="var(--col-taxi-yellow-light)"
						borderColor="var(--col-taxi-yellow)"
					/>
					<KPITextLine
						compare={compare}
						title={labels.plannedTime}
						value={shiftScheduleText}
						color="var(--col-lavendar-light)"
						borderColor="var(--col-lavendar)"
					/>
				</div>
			</div>
		</KpiCard>
	);
};

export default WorkTimeCard;
