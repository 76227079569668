import timestampService from "../../../service/timestampService";
import { TaskDependency, WorkTask } from "../../../types/ganttChart";
import { DependencyMessageFactory } from "../../../utils/dependencyUtil";
import { RuleConcordanceMessageFactory } from "../../../utils/violationUtils";

const HoverDependencyItem = ({
  dependency,
}: {
  dependency: TaskDependency;
}) => {
  return (
    <>
      <li className="mb-[var(--spacing-sm)] flex flex-col">
        <span>
          <b>
            {dependency.employeeName} (
            {timestampService.getHoursAndMinutesTimestamp(dependency.start)}
            {" - "}
            {timestampService.getHoursAndMinutesTimestamp(dependency.end)})
          </b>
        </span>
        {DependencyMessageFactory.getDependencyMessages(dependency!).map(
          (message) => (
            <span>{message}</span>
          )
        )}
      </li>
    </>
  );
};

export default HoverDependencyItem;
