import Accordion from '../UI/Accordion';
import headerStyles from '../../styles/header.module.css';
import HeaderCalendar from './HeaderCalendar';
import HeaderKPIList from './HeaderKPIList';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
	dequeueAffectedNexusLandingPlanningDate,
	resetSolutionsOnPlanningDate,
	setActiveNexusFilter,
} from '../../store/nexusFilters';
import { getActiveNexusFilters, getPlanningDates } from '../../store/nexusFilters/action';
import { useEffect } from 'react';
import { ReactComponent as Logo } from '../../assets/Pondoo_clean_logo.svg';
import { getGanttChartData } from '../../store/ganttChart/action';
import { getKPICollection } from '../../store/KPICollection/action';
import { useSearchParams } from 'react-router-dom';
import {
	clearGanttChartData,
	resetGanttChartErrorMessage,
	setShowSpinner,
	clearPondooStatusData,
	setHideSpinner,
} from '../../store/ganttChart';
import labels from '../../utils/labels';
import { clearKpitData, setCompareWithNexus } from '../../store/KPICollection';
import Switch from '../UI/input/Switch';
import { usePathCheck } from '../../hooks/usePathCheck';
import SendToNexus from './SendToNexusButton';
import { useNexusPlanStatus, usePondooPlanStatus, useSelectedFilterData } from '../../store/serverAsyncJobs/hooks';
import HeaderFilters from '../ganttChart/filtering/HeaderFilter';

const Header = () => {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
	const date = searchParams.get('date');
	const isKpiPage = usePathCheck();
	const compareWithNexus = useAppSelector((state) => state.kpiCollectionSlice.comepareWithNexus);
	const interceptorIsReady = useAppSelector((state) => state.apiConfigurationState.interceptorIsReady);
	const nexusPlanStatus = useNexusPlanStatus();
	const pondooPlanStatus = usePondooPlanStatus();

	const { planningDates, queuedNexusLandingPlanningDateToReset } = useAppSelector((state) => state.nexusFiltersSlice);
	const selectedData = useSelectedFilterData();

	/**
	 * Initial call to hydrate planningDate objects - gives a "data readiness" overview for each day
	 */
	useEffect(() => {
		if (interceptorIsReady) dispatch(getActiveNexusFilters());
	}, [interceptorIsReady, dispatch]);

	/**
	 * Handle manual override of planning dates if and only if user has initiated the app via Nexus
	 */
	useEffect(() => {
		if (!planningDates) return;

		if (queuedNexusLandingPlanningDateToReset) {
			// User started the app via Nexus, so we must override the spinner behavior here
			dispatch(resetSolutionsOnPlanningDate(queuedNexusLandingPlanningDateToReset));
			dispatch(dequeueAffectedNexusLandingPlanningDate());
		}
	}, [planningDates, queuedNexusLandingPlanningDateToReset]);

	const { activeNexusFilters, selectedActiveNexusFilter } = useAppSelector((state) => state.nexusFiltersSlice);

	const { selectedGanttChartTab, ganttChartData } = useAppSelector((state) => state.ganttChartSlice);

	/**
	 * Determine state of the spinner
	 */
	useEffect(() => {
		if (selectedGanttChartTab === 'Original') {
			if (!!nexusPlanStatus && nexusPlanStatus.isLoading) dispatch(setShowSpinner());
			if (!!nexusPlanStatus && nexusPlanStatus.isReady) dispatch(setHideSpinner());
		}

		if (selectedGanttChartTab === 'Optimeret') {
			if (!!pondooPlanStatus && pondooPlanStatus.isLoading) dispatch(setShowSpinner());
			if (!!pondooPlanStatus && pondooPlanStatus.isReady) dispatch(setHideSpinner());
		}
	}, [date, nexusPlanStatus, pondooPlanStatus, selectedGanttChartTab]);

	useEffect(() => {
		// To do: add a check, so that ths only runs, if the pendingData change was related to the currently selected filters.
		if (
			(nexusPlanStatus.isReady && !!selectedData && selectedGanttChartTab === 'Original') ||
			(pondooPlanStatus.isReady && !!selectedData && selectedGanttChartTab === 'Optimeret')
		) {
			// TOdo: when landing on a page
			// Todo: come back to, to check if able to retrieve pondoo plan.

			dispatch(
				getGanttChartData({
					idActiveNexusFilter: selectedActiveNexusFilter!.id,
					planningDate: selectedData.selectedNexusFilterPlanningDate!,
					pondooSolution: selectedGanttChartTab === 'Optimeret',
				})
			);
			dispatch(
				getKPICollection({
					nexusIdSolution: selectedData.selectedNexusFilterPlanningDate!.nexusIdSolution,
					pondooIdSolution: selectedData.selectedNexusFilterPlanningDate!.pondooIdSolution,
				})
			);
		} else {
			dispatch(clearGanttChartData());
			dispatch(clearKpitData());
			dispatch(clearPondooStatusData());
		}
	}, [nexusPlanStatus, pondooPlanStatus, selectedGanttChartTab]);

	return (
		<div className={headerStyles.header}>
			<div className="flex items-center justify-between w-full">
				<div className="flex items-center gap-[var(--spacing-lg)]">
					<Logo width={40} height={40} />
					<div className={headerStyles.headerFilterWrapper}>
						{activeNexusFilters && <HeaderFilters options={activeNexusFilters} />}
						<HeaderCalendar />
						<HeaderKPIList />
					</div>
				</div>
				<div className='flex gap-[var(--spacing-lg)]'>
					{isKpiPage && ganttChartData && (
						<Switch
							label={labels.compareWithNexus}
							id="compare"
							checked={compareWithNexus}
							onChange={() => dispatch(setCompareWithNexus(!compareWithNexus))}
							labelPlacement="BEFORE"
							width={240}
						/>
					)}
					{selectedGanttChartTab === 'Optimeret' && (
						<SendToNexus
							disable={!ganttChartData}
							idScenario={ganttChartData?.idScenario ? ganttChartData?.idScenario : -1}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default Header;
