import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initiateGanttChartDataCase } from "./reactivity";
import { Job, WebSocketMessagePayload } from "../../hooks/WebSocketProvider";
import { hydrateJobEntitiesCases, hydrateRunningJobsCases } from "./actions";
import { decodeJobId, findJobIdByFilterAndDate } from "./helpers";

export type ServerAsyncJobsSlice = {
  // Ongoing processes mediated through the websocket connection
  websocketJobs: Record<string, WebSocketMessagePayload[]>;
  // Job objects created during the optimization process - used to retrieve the completedTime
  jobEntities: Job[];
};

const initialState: ServerAsyncJobsSlice = {
  websocketJobs: {},
  jobEntities: [],
};

const serverAsyncJobsSlice = createSlice({
  name: "server-async-jobs",
  initialState,
  reducers: {
    /**
     * Adds a new job to the list of active jobs or updates the state of one if it already exists
     * @param state
     * @param action
     */
    addOrUpdateJob: (state, action: PayloadAction<WebSocketMessagePayload>) => {
      const job = action.payload;
      state.websocketJobs[job.jobId] = [
        ...(state.websocketJobs[job.jobId] ?? []),
        action.payload,
      ];
    },
    createPendingJobWithJobId: (state, action: PayloadAction<string>) => {
      const { filter, date } = decodeJobId(action.payload);
      const existingJobId = findJobIdByFilterAndDate(
        state.websocketJobs,
        filter,
        date,
      );
      if (existingJobId) delete state.websocketJobs[existingJobId];
      state.websocketJobs[action.payload] = [];
    },
  },
  extraReducers: (builder) => {
    initiateGanttChartDataCase(builder);
    hydrateRunningJobsCases(builder);
    hydrateJobEntitiesCases(builder);
  },
});

export const { addOrUpdateJob, createPendingJobWithJobId } =
  serverAsyncJobsSlice.actions;

export default serverAsyncJobsSlice.reducer;
