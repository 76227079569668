import { ReactComponent as WarningIcon } from "../../assets/icons/warning.svg";
import violationStyles from "../../styles/UI/violation.module.css";
import { getViolationMessagesFromViolationName } from "../../utils/violationUtils";

const ViolationBox = ({
  violations,
}: {
  violations: Record<string, string>[];
}) => {
  return (
    <div className={violationStyles.violationBox}>
      <div className="flex gap-[--spacing-sm] items-center">
        <WarningIcon width={14} height={14} />
        <p className={violationStyles.violationBoxTitle}>Regelbrud</p>
      </div>
      <ul className={violationStyles.violationBoxList}>
        {violations.map((violationItem, idx) => (
          <li key={idx} className={violationStyles.violationBoxText}>
            {getViolationMessagesFromViolationName(violationItem["name"])}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ViolationBox;
