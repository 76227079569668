import { WorkTask } from "../../types/ganttChart";
import labels from "../../utils/labels";
import drawerStyles from "../../styles/drawer.module.css";
import DrawerBlock from "./DrawerBlock";

const SubTasksList = ({ task }: { task: WorkTask }) => {
  return (
    <DrawerBlock title={labels.tasks}>
      <section className={drawerStyles.subTasksList}>
        {task.subTasks.map((subTask, index) => (
          <div key={index} className={drawerStyles.subTaskWrapper}>
            {subTask.description}
          </div>
        ))}
      </section>
    </DrawerBlock>
  );
};

export default SubTasksList;
