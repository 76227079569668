import { createSlice } from "@reduxjs/toolkit";

export interface DragAndDropSlice {
  yPosition?: number;
  xPosition?: number;
  isDragging: boolean;
  canDrop: boolean;
}

const initialState: DragAndDropSlice = {
  isDragging: true,
  canDrop: false,
};

const dragAndDropSSlice = createSlice({
  name: "drag-and-drop",
  initialState,
  reducers: {
    setIsDragging(state) {
      state.isDragging = true;
    },
    setCoordinates(state, action) {
      state.xPosition = action.payload.x;
      state.yPosition = action.payload.y;
    },
    dropTask(state) {
      delete state.xPosition;
      delete state.yPosition;
      state.isDragging = false;
    },
  },
});

export const { setIsDragging, setCoordinates, dropTask } =
  dragAndDropSSlice.actions;

export default dragAndDropSSlice.reducer;
