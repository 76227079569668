import GreyOpacityText from "../UI/textFields/GreyOpacityText";
import drawerStyles from "../../styles/drawer.module.css";

const InfoFieldTextBoxGreyBlackColumns = ({
  firstColumn,
  secondColumn,
}: {
  firstColumn: React.ReactNode;
  secondColumn: React.ReactNode | string;
}) => {
  return (
    // To do: is this being used?
    <div className={drawerStyles.infoTextLines}>
      <GreyOpacityText size="MEDIUM">{firstColumn}</GreyOpacityText>
      <p className="flex flex-col items-end leading-tight ">{secondColumn}</p>
    </div>
  );
};

export default InfoFieldTextBoxGreyBlackColumns;
