import { ReactComponent as ViolationIcon } from "../../assets/icons/warning.svg";
const ViolationChip = ({
  height,
  width,
}: {
  height: number;
  width: number;
}) => {
  return <ViolationIcon height={14} width={14} />;
};

export default ViolationChip;
