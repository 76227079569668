import { ReactComponent as WarningIcon } from "../../assets/icons/warning_yellow.svg";
import warningStyles from "../../styles/UI/warning.module.css";
import { getViolationMessagesFromViolationName } from "../../utils/violationUtils";

const WarningBox = ({
  violations,
}: {
  violations: Record<string, string>[];
}) => {
  return (
    <div className={warningStyles.warningBox}>
      <div className="flex gap-[--spacing-sm] items-center">
        <WarningIcon width={14} height={14} />
        <p className={warningStyles.warningBoxTitle}>Information</p>
      </div>
      <p className={warningStyles.warningBoxText}>
        Der beregnes ikke transport til dette besøg
      </p>
    </div>
  );
};

export default WarningBox;
