import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { Stomp, IFrame, Message, CompatClient, Client } from "@stomp/stompjs";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import SockJS from "sockjs-client";
import { addOrUpdateJob } from "../store/serverAsyncJobs";
import { WebSocketMessageSideEffectService } from "../service/WebSocketMessageSideEffectService";
import { AuthService } from "../service/api";
import { setGanttChartErrorMessage } from "../store/ganttChart";
import AppInitializationService from "../service/AppInitializationService";

// Messaging stuff

export type NexusGanttChartDataReadyResponse = {
  idActiveNexusFilter: number;
  idPlanningDate: number;
};

export type Job = {
  id: number;
  jobRef: string;
  createdDateTime: string;
  updatedDateTime: string;
  completedDateTime: string;
  jobStatus: string;
  idResponsibleUser: number;
  idScenario: number;
};

export type MessageType =
  | "NexusLandingProgressOptimizationInitiated"
  | "NexusLandingProgressOptimizationFinished"
  | "NexusLandingDataRetrievalAlreadyRunningMessage"
  | "NexusLandingProgressDataRetrievalInitiatedMessage"
  | "NexusLandingProgressNexusDataReadyMessage"
  | "NexusLandingProgressDataRetrievalFailedMessage";

export type MessageEnvelope<T> = {
  bridgeMessageType: MessageType;
  jobId: string;
  data: T;
};

export type NexusLandingProgressOptimizationInitiatedMessage =
  MessageEnvelope<Job> & {
    bridgeMessageType: "NexusLandingProgressOptimizationInitiated";
  };

export type NexusLandingProgressOptimizationFinishedMessage =
  MessageEnvelope<Job> & {
    bridgeMessageType: "NexusLandingProgressOptimizationFinished";
  };

export type NexusLandingProgressNexusDataReadyMessage =
  MessageEnvelope<NexusGanttChartDataReadyResponse> & {
    bridgeMessageType: "NexusLandingProgressNexusDataReadyMessage";
  };

export type NexusLandingDataRetrievalAlreadyRunningMessage =
  MessageEnvelope<void> & {
    bridgeMessageType: "NexusLandingDataRetrievalAlreadyRunningMessage";
  };

export type NexusLandingProgressDataRetrievalInitiatedMessage =
  MessageEnvelope<void> & {
    bridgeMessageType: "NexusLandingProgressDataRetrievalInitiatedMessage";
  };

export type NexusLandingProgressDataRetrievalFailedMessage =
  MessageEnvelope<string> & {
    bridgeMessageType: "NexusLandingProgressDataRetrievalFailedMessage";
  };

export type WebSocketMessagePayload =
  | NexusLandingProgressOptimizationInitiatedMessage
  | NexusLandingProgressNexusDataReadyMessage
  | NexusLandingProgressOptimizationFinishedMessage
  | NexusLandingDataRetrievalAlreadyRunningMessage
  | NexusLandingProgressDataRetrievalInitiatedMessage
  | NexusLandingProgressDataRetrievalFailedMessage;

// Context provider

export type WebSocketContextType = {
  client: CompatClient | null;
  readyState: number;
};

const WebSocketContext = createContext<WebSocketContextType>({
  client: null,
  readyState: WebSocket.CLOSED,
});

export const WebSocketProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [stompClient, setStompClient] = useState<CompatClient | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [readyState, setReadyState] = useState<number>(WebSocket.CLOSED);
  const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isConnectingRef = useRef(false);

  const dispatch = useAppDispatch();
  const { interceptorIsReady, tokenSetCount } = useAppSelector(
    (state) => state.apiConfigurationState,
  );
  const sideEffectService = new WebSocketMessageSideEffectService(dispatch);
  const appInitializationService = new AppInitializationService(dispatch);

  const RECONNECT_DELAY = 2000; // 2 seconds

  // TODO: Clean up the log statements once this has been properly verified

  const tryResetTokens = async () => {
    // Refresh access token if needed; can happen if user didn't interact for a while
    const refreshToken = localStorage.getItem("refresh_token");
    const errorMessage =
      "Kunne ikke etablere en sikker forbindelse til Nexus. Prøv at logge ind.";
    if (!refreshToken) {
      dispatch(setGanttChartErrorMessage(errorMessage));
      return;
    }

    const tokens = await AuthService.refreshConcreteToken(refreshToken);
    if (!tokens) {
      dispatch(setGanttChartErrorMessage(errorMessage));
      return;
    }

    appInitializationService.configureAppInitialization(
      tokens.accessToken,
      tokens.refreshToken,
    );
    setAccessToken(tokens.accessToken);
  };

  // Synchronize the accessToken with changes to localStorage
  useEffect(() => {
    if (!interceptorIsReady) return;

    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      console.error("Could not retrieve an access token");
      return;
    }
    setAccessToken(accessToken);
  }, [interceptorIsReady, tokenSetCount]);

  const handleMessage = useCallback(
    (message: WebSocketMessagePayload) => {
      dispatch(addOrUpdateJob(message));
      sideEffectService.handleSideEffects(message);
    },
    [dispatch],
  );

  const cleanupConnection = useCallback(() => {
    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
      reconnectTimeoutRef.current = null;
    }

    if (stompClient?.connected) {
      stompClient.disconnect();
    }
    setStompClient(null);
    setReadyState(WebSocket.CLOSED);
  }, [stompClient]);

  const connectWebSocket = useCallback(() => {
    if (!accessToken || isConnectingRef.current) return;

    isConnectingRef.current = true;
    const wsRoot = window._env_.REACT_APP_WS_URL;
    const socket = new SockJS(`${wsRoot}`);
    const client = Stomp.over(socket);

    // Disable debug logs in production
    if (process.env.NODE_ENV === "production") {
      client.debug = () => {};
    }

    const handleConnect = (frame: IFrame) => {
      console.log("Connected: " + frame);
      setReadyState(WebSocket.OPEN);
      isConnectingRef.current = false;

      client.subscribe("/bridge", (message: Message) => {
        console.log("Received: " + message.body);
        const parsedMessage: WebSocketMessagePayload = JSON.parse(message.body);
        handleMessage(parsedMessage);
      });
    };

    const handleError = async (error: unknown) => {
      console.log("STOMP error: " + error);
      await tryResetTokens();
      setReadyState(WebSocket.CLOSED);
      isConnectingRef.current = false;
      scheduleReconnect();
    };

    const scheduleReconnect = () => {
      if (reconnectTimeoutRef.current) return;

      console.log(`Attempting to reconnect in ${RECONNECT_DELAY}ms`);
      reconnectTimeoutRef.current = setTimeout(() => {
        reconnectTimeoutRef.current = null;
        cleanupConnection();
        connectWebSocket();
      }, RECONNECT_DELAY);
    };

    const handleDisconnect = async () => {
      console.log("Disconnected");
      setReadyState(WebSocket.CLOSED);
      isConnectingRef.current = false;

      await tryResetTokens();
    };

    client.connect(
      { Authorization: `Bearer ${accessToken}` },
      handleConnect,
      handleError,
    );

    client.onDisconnect = handleDisconnect;

    setStompClient(client);
  }, [accessToken, handleMessage, cleanupConnection]);

  // Initialize WebSocket connection
  useEffect(() => {
    if (accessToken) {
      connectWebSocket();
    }

    return () => {
      cleanupConnection();
    };
  }, [accessToken]);

  const value: WebSocketContextType = {
    client: stompClient,
    readyState,
  };

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};
