import ganttChartStyles from "../../../../styles/ganttChart/ganttChart.module.css";

const TypedRowInLeftSideColumn = ({
  platform,
  bgColor,
  height,
  obs,
}: {
  platform: string;
  bgColor: string;
  height: number;
  obs?: string;
}) => {
  return (
    <div
      className={ganttChartStyles.typedRow}
      style={{
        backgroundColor: bgColor,
        height: height.toString() + "px",
      }}
    >
      <p className={ganttChartStyles.platformField}>{platform}</p>
      <p className={ganttChartStyles.obsField}>{obs}</p>
    </div>
  );
};

export default TypedRowInLeftSideColumn;
