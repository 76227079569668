import {
  OperationalTask,
  TransportationTask,
  WorkTask,
} from "../types/ganttChart";

export class HighlightTasks {
  static getOpacity = (highlightedTaskIds: number[], taskId: number) => {
    const isHighlightedOrEverythingShouldBeVisible =
      highlightedTaskIds.includes(taskId) || highlightedTaskIds.length === 0;
    return isHighlightedOrEverythingShouldBeVisible ? 1 : 0.2;
  };
}
