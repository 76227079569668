import { ReactComponent as ArrowIcon } from "../../assets/icons/black-arrow-down.svg";
import dropdownStyles from "../../styles/UI/accordion.module.css";
import { useEffect, useState } from "react";

export type AccordionVariant = "PRIMARY" | "SECONDARY";

const Accordion = <T,>({
  onChange,
  variant,
  options,
  labelKey,
  defaultText,
  displayOption,
}: {
  onChange: (option: T) => void;
  variant: AccordionVariant;
  options: T[];
  labelKey?: keyof T;
  defaultText: string;
  displayOption?: number; //if user wants to show a partiluar option by index by default
}) => {
  const [open, toggleOpen] = useState<boolean>();
  const [selectedOption, setSelectedOption] = useState<T>();
  const [displayText, setDisplayText] = useState<string>();

  useEffect(() => {
    if (selectedOption) {
      setDisplayText(
        labelKey
          ? (selectedOption[labelKey] as string)
          : (selectedOption as string)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  useEffect(() => {
    if (displayOption !== undefined && displayOption !== null) {
      setSelectedOption(options[displayOption]);
      onChange(options[displayOption]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative">
      <button
        className={`${dropdownStyles.accordion} ${variant === "PRIMARY" ? dropdownStyles.accordion__primary : dropdownStyles.accordion__secondary} `}
        onClick={() => {
          toggleOpen((prevState) => !prevState);
        }}
      >
        {selectedOption ? displayText : defaultText}
        <ArrowIcon width={20} />
      </button>
      {open && (
        <ul
          className={`${dropdownStyles.accordionList} ${variant === "PRIMARY" ? dropdownStyles.accordionList__primary : dropdownStyles.accordionList__secondary} `}
        >
          {options.map((option, index) => (
            <li
              key={`option_${index}`}
              className={`${selectedOption === option && dropdownStyles.accordionListItem__selected}`}
              onClick={() => {
                toggleOpen((prevState) => !prevState);
                setSelectedOption(option);
                onChange(option);
              }}
            >
              {labelKey ? (option[labelKey] as string) : (option as string)}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Accordion;
